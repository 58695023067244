import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";
import { GlobalFilter } from "../Roles/GlobalFilter";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  useLazyGetStatusSubcriptionQuery,
  useLazyGetSubscriptionQuery,
} from "../../../constants/redux/api/api";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      {/* show */}
      <Row className="mb-3 table-show row">
        <Col className="d-flex align-items-center">
          <p>Show</p>

          <select
            className="form-control w-auto mx-2 p-2 py-1"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <p>entries</p>
        </Col>
        <Col className="d-flex justify-content-end">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {/* <Link to="/support/add-support">
            <Button style={{ background: 'rgb(95,188,95)' }} className="btn-sm btn-round has-ripple ml-2 border-0">
              <i className="feather icon-plus" /> Add Ticket
            </Button>
          </Link> */}
        </Col>
      </Row>
      {/* show */}
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      {/* pagination */}
      <Row className="pagebtm justify-content-between table-show my-2">
        <Col>
          <span className="d-flex align-items-center">
            <p className="px-1">Page</p>{" "}
            <strong>
              {" "}
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>{" "}
            <p className="px-1">|</p>
            <p className="px-1">Go to page:</p>{" "}
            <input
              type="number"
              className="form-control ml-2  p-1 py-1 text-center"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "40px" }}
            />
          </span>
        </Col>
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
      {/*paginations  */}
    </>
  );
}

const Subscription = () => {
  const navigate = useNavigate();
  const permissons = JSON.parse(localStorage.getItem("role"));
  const [getSubscriptionApi] = useLazyGetSubscriptionQuery();
  const [getStatusSubcriptionApi] = useLazyGetStatusSubcriptionQuery();

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: "Tittle",
        accessor: "name",
        Cell: ({ value, row }) => {
          return <span>{value}</span>;
        },
      },

      {
        Header: "Price",
        accessor: "actual_price",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Offer Price",
        accessor: "offer_price",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "No of Days",
        accessor: "no_of_days",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ value, row }) => {
          return (
            <>
              {permissons?.includes("CAN-STATUS-SUBSCRIPTIONS") ? (
                <button
                  onClick={() => changeStatus(row.original.id)}
                  className={
                    value == 1
                      ? "p-2 py-1 comn-btn active-deact-btn bg-success"
                      : "p-2 py-1 comn-btn active-deact-btn bg-danger"
                  }
                >
                  {value == 1 ? "Activate" : "Deactivate"}
                </button>
              ) : (
                <button
                  className={
                    value == 1
                      ? "p-2 py-1 comn-btn active-deact-btn bg-success"
                      : "p-2 py-1 comn-btn active-deact-btn bg-danger"
                  }
                >
                  {value == 1 ? "Activate" : "Deactivate"}
                </button>
              )}
            </>
          );
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              {/* {permissons?.includes("CAN-EDIT-SUBSCRIPTIONS") ? ( */}
              <button
                onClick={() =>
                  navigate("/subscription-add", {
                    state: { id: row.original.id, type: "edit" },
                  })
                }
                className="comn-btn bg-primary edit-btn-svg"
              >
                <FontAwesomeIcon icon={faPen} />
              </button>
              {/* ) : null} */}
            </React.Fragment>
          );
        },
      },
      // {
      //   Header: "Deatail View",
      //   accessor: "Deatail",
      //   Cell: ({ value, row }) => {
      //     return (
      //       <React.Fragment>
      //         <button
      //           onClick={() =>
      //             navigate("/subscription/detail-view", {
      //               state: { id: row.original.id },
      //             })
      //           }
      //           className="comn-btn bg-primary edit-btn-svg"
      //         >
      //           <FontAwesomeIcon icon={faEye} />
      //         </button>
      //       </React.Fragment>
      //     );
      //   },
      // },
    ],
    []
  );

  const [input, setInput] = useState([]);
  const [Id, setId] = useState();
  const [btn, setBtn] = useState(false);
  const [list, setList] = useState([]);

  const getList = () => {
    getSubscriptionApi()
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setList(res?.subscriptions);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setInput((val) => ({ ...val, [name]: value }));
  };

  const add = () => {
    let formData = new FormData();
    formData.append("name", input?.name);
    formData.append("amount", input?.price);
    formData.append("validity", input?.validity);
    setBtn(true);
  };

  const edit = () => {
    let formData = new FormData();
    formData.append("name", input?.name);
    formData.append("amount", input?.price);
    formData.append("validity", input?.validity);
    formData.append("_method", "PUT");

    setBtn(true);
  };

  const changeStatus = (id) => {
    getStatusSubcriptionApi(id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          toast.success(res?.message, {
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            autoClose: 2000,
          });
          getList();
        }
      });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <aside>
      <TopHeader type="subscription" />
      <article className="p-3">
        <div className="minht p-2 bg-white rounded-2">
          {/* <div className="ad-btn w-100 flex-ae-jb px-0 py-2">
            <Col md={3} xs={6} className="px-2 my-2">
              <span>From Date</span>
              <input
                type="date"
                className="form-control"
                onChange={startDate}
                value={from}
              />
            </Col>
            <Col md={3} xs={6} className="px-2 my-2">
              <span>To Date</span>
              <input
                type="date"
                className="form-control"
                onChange={ToDate}
                value={to}
              />
            </Col>
          </div> */}
          <div className="d-flex justify-content-end px-2 py-3">
            {/* {permissons?.includes("CAN-ADD-SUBSCRIPTIONS") ? ( */}
            <button
              onClick={() => navigate("/subscription-add")}
              className="comn-btn bg-primary1 px-3 py-2"
            >
              Add Subscription
            </button>
            {/* ) : null} */}
          </div>
          <Table columns={columns} data={list} />
        </div>
      </article>
    </aside>
  );
};

export default Subscription;
