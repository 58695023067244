import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { MdOutlineNotificationsNone } from "react-icons/md";
import TopHeader from "../../../component/TopHeader";
import { useLazyNotifyListQuery } from "../../../constants/redux/api/api";

function Notification() {
  const [notify, setNotifiy] = useState([]);
  const [notifyStatus, setNotifiyStatus] = useState();
  //RTK
  const [notificationApi]=useLazyNotifyListQuery()

  const notificationList = () => {
    notificationApi().unwrap().then((res) => {
      setNotifiy(res?.notifications);
    });
  };

  // useEffect(() => {
  //   notificationList();
  // }, []);

  return (
    <aside>
      <TopHeader type="push-notification" />
      <article className="p-3">
        <div className="p-2 bg-white rounded-2">
          {notify?.map((list) => (
            <ul style={{ listStyleType: "none" }} className="list-group">
              <li className="noti-noti list-group-item border-0" sm={10}>
                <p>
                  <MdOutlineNotificationsNone />
                  <b>{"#" + list?.id}</b>
                </p>
                <p>{list?.description}</p>
                <p className="created_at">
                  {list?.created_at
                    ? moment(list?.created_at).format("DD/MM/YYYY, hh:mm A")
                    : ""}
                </p>
              </li>
            </ul>
          ))}
        </div>
      </article>
    </aside>
  );
}

export default Notification;
