import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";

import { GlobalFilter } from "../Roles/GlobalFilter";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  useLazyOtherChargeListQuery,
  useOtherChargeDeleteMutation,
} from "../../../constants/redux/api/api";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead className="text-center">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="text-center">
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      <Row className="justify-content-between table-show my-2">
        <Col>
          <span className="d-flex align-items-center">
            <p className="px-1">Page</p>{" "}
            <strong>
              {" "}
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>{" "}
            <p className="px-1">|</p>
            <p className="px-1">Go to page:</p>{" "}
            <input
              type="number"
              className="form-control ml-2  p-1 py-1 text-center"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "40px" }}
            />
          </span>
        </Col>
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

const Outstation = ({
  setEditModal,
  setAddModal,
  addModal,
  addFare,
  btn,
  editModal,
  handleChange,
  input,
  listOutStation,
  viewFare,
  editFare,
  deleteFare,
  popUpCancel,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const Id = location?.state?.id;
  const type = location?.state?.type;
  const permissons = JSON.parse(localStorage.getItem("role"));

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },

      {
        Header: "Hour",
        accessor: "packageDisplay",  
        Cell: ({ value, row }) => {
          const pkgArray = row.original.package;
          if (Array.isArray(pkgArray) && pkgArray.length > 0) {
            return (
              <p>{pkgArray.map((pkg) => pkg.display).join(", ")}</p>
            );
          } else {
            return <p>-</p>;
          }
        },
      },
      {
        Header: "Minutes",
        accessor: "packageMinutes",  
        Cell: ({ value, row }) => {
          const pkgArray = row.original.package;
          if (Array.isArray(pkgArray) && pkgArray.length > 0) {
            return (
              <p>{pkgArray.map((pkg) => pkg.minutes).join(", ")}</p>
            );
          } else {
            return <p>-</p>;
          }
        },
      },
      {
        Header: "Price",
        accessor: "price_per_min",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              {/* {permissons?.includes("CAN-EDIT-FARE PRICE") ? ( */}
              <div className="d-flex gap-2 justify-content-center">
                <button
                  className="comn-btn bg-primary edit-btn-svg"
                  onClick={() => viewFare(row.original)}
                >
                  <FontAwesomeIcon icon={faPen} />
                </button>

                {/* <button
                  className="comn-btn bg-danger edit-btn-svg"
                  onClick={() => deleteFare(row.original.id)}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button> */}
              </div>
              {/* ) : null} */}
            </React.Fragment>
          );
        },
      },
    ],
    []
  );

  return (
    <aside>
      <article className="p-3">
        <div className="p-2 bg-white rounded-2">
          <div className="ad-btn w-100 flex-ac-jb p-2 d-flex justify-content-end">
            <div className="flex-ac-jc">
              {/* <button
                onClick={() => setAddModal(true)}
                className="comn-btn bg-primary1 px-3 py-2"
              >
                Add Outstation One Way
              </button> */}
            </div>
          </div>
          <Table columns={columns} data={listOutStation} />
        </div>
      </article>

      <Modal
        className="table-modal"
        show={addModal}
        onHide={() => setAddModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="px-2 py-2 col-12 d-flex flex-wrap">
          <div className="px-1 col-sm-12 modal-label-input">
            <h4>Add Outstation One Way</h4>
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Hour
            </label>
            <input
              type="text"
              id="example-if-email"
              name="hours"
              onChange={handleChange}
              placeholder="hours"
              value={input?.hours}
            />
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Price
            </label>
            <input
              type="text"
              id="example-if-email"
              name="price_per_min"
              onChange={handleChange}
              placeholder="Price"
              value={input?.price_per_min}
            />
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Min. Minutes
            </label>
            <input
              type="text"
              id="example-if-email"
              name="min_minutes"
              onChange={handleChange}
              placeholder="Min. Minutes"
              value={input?.min_minutes}
            />
          </div>
        </div>
        <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
          <button
            onClick={() => {
              popUpCancel();
            }}
            className="comn-btn bg-hash2 px-3 py-2 me-2"
          >
            Cancel
          </button>
          <button
            type="button"
            className="comn-btn bg-success px-3 py-2 me-2"
            data-dismiss="modal"
            onClick={() => addFare(3)}
            disabled={btn ? true : false}
          >
            Submit
          </button>
        </div>
      </Modal>

      <Modal
        className="table-modal"
        show={editModal}
        onHide={() => setEditModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="px-2 py-2 col-12 d-flex flex-wrap">
          <div className="px-1 col-sm-12 modal-label-input">
            <h4>Edit Outstation One Way</h4>
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Hour
            </label>
            <input
              type="text"
              id="example-if-email"
              name="hours"
              onChange={handleChange}
              placeholder="hours"
              value={input?.hours}
            />
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Price
            </label>
            <input
              type="text"
              id="example-if-email"
              name="price_per_min"
              onChange={handleChange}
              placeholder="Price"
              value={input?.price_per_min}
            />
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Min. Minutes
            </label>
            <input
              type="text"
              id="example-if-email"
              name="min_minutes"
              onChange={handleChange}
              placeholder="Min. Minutes"
              value={input?.min_minutes}
            />
          </div>
        </div>
        <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
          <button
            onClick={() => {
              popUpCancel();
            }}
            className="comn-btn bg-hash2 px-3 py-2 me-2"
          >
            Cancel
          </button>
          <button
            type="button"
            className="comn-btn bg-success px-3 py-2 me-2"
            data-dismiss="modal"
            onClick={() => editFare(3)}
            disabled={btn ? true : false}
          >
            Submit
          </button>
        </div>
      </Modal>
    </aside>
  );
};

export default Outstation;
