import React, { useState, useEffect } from "react";
import { Col, Form, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Input from "../../component/UI/input";
import { hidePwdImg, LoginLeft, Logo, showPwdImg } from "../../assets/img";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import useToken from "../../constants/Storage/useToken";
import useUser from "../../constants/Storage/userData";
import { useLoginMutation } from "../../constants/redux/api/api";

const SignIn = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token, setToken } = useToken();
  const { user, setUser } = useUser();
  const [name, setName] = useState("");
  const [pass, setPass] = useState("");
  const [nameErr, setnameErr] = useState(false);
  const [passErr, setpassErr] = useState(false);
  const [btn, setBtn] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  const [LoginAPi] = useLoginMutation();

  const submitHandle = () => {
    // setToken("token");
    // setUser("{admin:res?.admin}");
    if (name?.length == 0 || pass?.length < 8) {
      setnameErr(true);
      setpassErr(true);
    } else {
      let formdata = new FormData();
      formdata.append("username", name);
      formdata.append("password", pass);
      formdata.append("fcm_id", localStorage.getItem("fcm_id"));
      LoginAPi(formdata)
        .unwrap()
        .then((res) => {
          if (res?.status == "success") {
            setBtn(false);
            setToken(res?.token);
            setUser(res?.admin);
            // localStorage.setItem("token", res?.token);
            localStorage.setItem("role", JSON.stringify(res?.permissions));
            toast.success(res?.message, {
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              autoClose: 2000,
            });
            setTimeout(() => {
              navigate("/");
              window.location.reload();
            }, 800);
          }
        })
        .catch((err) => {
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  const keydownHandler = (event) => {
    if (event.key == "Enter") {
      submitHandle();
    }
  };

  return (
    <Col xs={12} className="d-flex vh-100 log-in">
      <Col xs={12} md={6} className="left-log-in">
        <div className="log-in-left">
          <Image
            className=""
            src={
              "https://wheely.sgp1.cdn.digitaloceanspaces.com/pat%20splash%20R%206.gif"
            }
          />
        </div>
      </Col>
      <Col xs={12} md={6} className="vh-100 flex-ac-jc bg-background1_cus">
        <div className="bg-background rounded-3 px-4 py-5 w-75 login-box">
          <div className="flex-ac-jc">
            <Image className="log-logo me-2" src={""} />
          </div>
          <h4 className="sign-in-text txt-primary4_cus mb-0 mt-0">Sign In</h4>
          <div class="px-0 col-sm-12 modal-label-input">
            <label class="text-primary-dark" for="">
              User Name
            </label>
            <input
              type="Name"
              id="example-if-email"
              name="name"
              onChange={(e) => setName(e.target.value)}
              placeholder="username"
              className="px-2 mt-1"
              value={name}
              autocomplete="off"
              onKeyDown={keydownHandler}
            />
            {name?.length == 0 && nameErr && (
              <span className="err">Enter the Username</span>
            )}
          </div>
          <div class="px-0 col-sm-12 modal-label-input">
            <label class="text-primary-dark" for="">
              Password
            </label>
            <div className="pwd-container">
              <input
                type={isRevealPwd ? "text" : "password"}
                name="password"
                onChange={(e) => setPass(e.target.value)}
                placeholder="******"
                className="px-2 mt-1"
                value={pass}
                autocomplete="off"
                onKeyDown={keydownHandler}
              />
              <img
                src={isRevealPwd ? showPwdImg : hidePwdImg}
                onClick={() => setIsRevealPwd((prevState) => !prevState)}
              />
            </div>
            {pass?.length < 8 && passErr && (
              <span className="err">Enter 8 digit Password</span>
            )}
          </div>

          {/* <Link
            to="/forgot-password"
            className="d-flex flex-row-reverse"
            style={{ fontSize: "14px", marginTop: "2px" }}
          >
            Forgot Password
          </Link> */}
          <div className="d-flex">
            <button
              onClick={submitHandle}
              className="comn-btn border-0 px-3 py-2 bg-primary1_login text-white rounded-1 text-decoration-none me-2"
              disabled={btn ? true : false}
            >
              Login
            </button>
          </div>
        </div>
      </Col>
    </Col>
  );
};

export default SignIn;
