import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";

import { GlobalFilter } from "../Roles/GlobalFilter";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  useLazyCouponDeleteQuery,
  useLazyCouponListQuery,
  useLazyRewardDeleteQuery,
  useLazyRewardListQuery,
} from "../../../constants/redux/api/api";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      {/* show */}
      <Row className="mb-3 table-show row">
        <Col className="d-flex align-items-center">
          <p>Show</p>

          <select
            className="form-control w-auto mx-2 p-2 py-1"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <p>entries</p>
        </Col>
        <Col className="d-flex justify-content-end">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {/* <Link to="/support/add-support">
            <Button style={{ background: 'rgb(95,188,95)' }} className="btn-sm btn-round has-ripple ml-2 border-0">
              <i className="feather icon-plus" /> Add Ticket
            </Button>
          </Link> */}
        </Col>
      </Row>
      {/* show */}
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      {/* pagination */}
      <Row className="justify-content-between table-show my-2">
        <Col>
          <span className="d-flex align-items-center">
            <p className="px-1">Page</p>{" "}
            <strong>
              {" "}
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>{" "}
            <p className="px-1">|</p>
            <p className="px-1">Go to page:</p>{" "}
            <input
              type="number"
              className="form-control ml-2  p-1 py-1 text-center"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "40px" }}
            />
          </span>
        </Col>
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
      {/*paginations  */}
    </>
  );
}

const Rewards = () => {
  const navigate = useNavigate();
  const permissons = JSON.parse(localStorage.getItem("role"));
  const [list, setList] = useState([]);
  const [btn, setBtn] = useState(false);

  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  //RTK
  const [rewardListApi] = useLazyRewardListQuery();
  const [rewardDeleteApi] = useLazyRewardDeleteQuery();

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <p>{row.index + 1}</p>;
        },
      },
      {
        Header: "Type",
        accessor: "type",
        Cell: ({ value, row }) => {
          return (
            <span>
              {value == 1
                ? "User Normal Coupon"
                : value == 2
                ? "Driver Normal Coupon"
                : value == 3
                ? "Reward Coupon"
                : "-"}
            </span>
          );
        },
      },
      {
        Header: "Reward Percentage",
        accessor: "reward_percentage",
        Cell: ({ value, row }) => {
          return <p>{value}</p>;
        },
      },
      {
        Header: "No of Days",
        accessor: "no_of_days",
        Cell: ({ value, row }) => {
          return <span>{value}</span>;
        },
      },

      {
        Header: "Count",
        accessor: "count",
        Cell: ({ value, row }) => {
          return <span>{value}</span>;
        },
      },

      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              <div className="d-flex gap-2">
                <button
                  className="comn-btn bg-primary edit-btn-svg"
                  onClick={() =>
                    navigate("/rewards/reward", {
                      state: {
                        type: "edit",
                        id: row.original.id,
                      },
                    })
                  }
                  disabled={btn}
                >
                  <FontAwesomeIcon icon={faEye} />
                </button>
                <button
                  className="comn-btn bg-danger edit-btn-svg"
                  onClick={() => deleteFares(row.original.id)}
                  disabled={btn}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </React.Fragment>
          );
        },
      },
    ],
    []
  );

  const getList = () => {
    let params = `?start_date=${from}&end_date=${to}`;
    rewardListApi()
      .unwrap()
      .then((res) => {
        setList(res?.rewards);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const startDate = (e) => {
    setFrom(e.target.value);
    setTo("");
  };

  const ToDate = (e) => {
    setTo(e.target.value);
  };

  useEffect(() => {
    getList();
  }, [from, to]);

  const deleteFares = (id) => {
    Swal.fire({
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete!",
    }).then((result) => {
      if (result.isConfirmed) {
        setBtn(true);
        rewardDeleteApi(id)
          .unwrap()
          .then((res) => {
            setBtn(false);
            Swal.fire("Delete", "Delete Successfully.", "success");
            getList();
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      }
    });
  };
  return (
    <aside>
      <TopHeader type="reward" />
      <article className="p-3">
        <aside>
          <article className="p-3">
            <div className="p-2 bg-white rounded-2">
              <div className="p-2 bg-white rounded-2">
                <div className="ad-btn w-100 flex-ac-jb p-2 d-flex justify-content-end">
                  <button
                    onClick={() => navigate("/rewards/reward")}
                    className="comn-btn bg-primary1 px-3 py-2"
                  >
                    Add Reward
                  </button>
                </div>
              </div>
              <div className="ad-btn w-100 flex-ae-jb px-0 py-2">
                <Col md={3} xs={6} className="px-2 my-2">
                  <span>From Date</span>
                  <input
                    type="date"
                    className="form-control"
                    onChange={startDate}
                    value={from}
                  />
                </Col>
                <Col md={3} xs={6} className="px-2 my-2">
                  <span>To Date</span>
                  <input
                    type="date"
                    className="form-control"
                    onChange={ToDate}
                    value={to}
                  />
                </Col>
              </div>
              <Table columns={columns} data={list} />
            </div>
          </article>
        </aside>
      </article>
    </aside>
  );
};

export default Rewards;
