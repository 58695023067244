import React, { useEffect, useState } from "react";
import Fare from "./fare";
import OtherCharge from "./otherCharge";
import PeakFare from "./peak";
import WaitingFare from "./waiting";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import OneWay from "./OneWay";
import Roundard from "./Roundard";
import Outstation from "./Outstation";
import {
  useFareAddMutation,
  useFareEditMutation,
  useLazyFareDeleteQuery,
  useLazyFareListQuery,
} from "../../../constants/redux/api/api";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import OutstationRoundard from "./OutstationRoundard";
function FareSetting() {
  const navigate = useNavigate();
  const location = useLocation();
  const [listOneWay, setOneWayList] = useState([]);
  const [listRoundard, setRoundradList] = useState([]);
  const [listOutStation, setOutStationList] = useState([]);
  const [listOutStationRoundard, setOutStationRoundardList] = useState([]);
  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [packages,setPackage]=useState([{display:"",minutes:""}]);
  
  const [input, setInput] = useState({});
  const [sub, setSub] = useState();
  const [btn, setBtn] = useState(false);

  // RTK QUERY
  const [fareAddApi] = useFareAddMutation();
  const [fareEditApi] = useFareEditMutation();
  const [listFareApi] = useLazyFareListQuery();
  const [deleteFareApi] = useLazyFareDeleteQuery();

  // GET API
  const getList = () => {
    listFareApi()
      .unwrap()
      .then((res) => {
        let oneWay = res?.prices?.filter((i) => i.type == 1);
        let roundard = res?.prices?.filter((i) => i.type == 2);
        let out_stataion = res?.prices?.filter((i) => i.type == 3);
        let out_stataion_roundard = res?.prices?.filter((i) => i.type == 4);
        setOneWayList(oneWay);
        setRoundradList(roundard);
        setOutStationList(out_stataion);
        setOutStationRoundardList(out_stataion_roundard);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setInput((val) => ({ ...val, [name]: value }));
  };

  // ADD API
  const addFare = (type) => {
    let formData = new FormData();
    formData.append("price_per_min", input?.price_per_min);
    formData.append("hours", input?.hours);
    formData.append("min_minutes", input?.min_minutes);
    formData.append("type", type);

    if (input?.price_per_min && input?.hours && input?.min_minutes) {
      setBtn(true);
      fareAddApi(formData)
        .unwrap()
        .then((res) => {
          setBtn(false);
          toast.success(res?.message, {
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            autoClose: 2000,
          });
          popUpCancel();
          getList();
        })
        .catch((err) => {
          setBtn(false);
        });
    } else {
      toast.error("Please Add All Details", {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        autoClose: 2000,
      });
    }
  };

  // // VIEW FUNCTION
  const viewFare = (item) => {
    const packageDisplays = item?.package?.map(pkg => pkg.display);
    const packageMinutes = item?.package?.map(pkg => pkg.minutes);
    let obj = {
      price_per_min: item?.price_per_min,
      minutes: packageMinutes,
      hours: packageDisplays,
      min_minutes:item?.min_minutes,
      id: item?.id,
    };
    setInput(obj);
    
    setEditModal(true);
  };
  // const viewFare = (item) => {
  //   const packageDisplays = item?.package?.map(pkg => pkg.display);
  //   const packageMinutes = item?.package?.map(pkg => pkg.minutes);
  //   let obj = {
  //     // price_per_min: item?.price_per_min,
  //     minutes: packageMinutes,
  //     hours: packageDisplays,
  //     // min_minutes:item?.min_minutes,
  //     // id: item?.id,
  //   };
  //   setInput(obj);
  //   setMinMinutes(item?.min_minutes);
  //   setId(item?.id);
  //   setPricePerMin(item?.price_per_min);
  //   setEditModal(true);
  // };
  // EDIT API
  const editFare = (type) => {
    let formData = new FormData();  
  
    input.hours.forEach((hour, index) => {
      formData.append(`package[${index}][display]`, hour);
      formData.append(`package[${index}][minutes]`, input.minutes[index]);
    });
  
    // formData.append("package", JSON.stringify(packages));
    formData.append("price_per_min", input.price_per_min);
    formData.append("min_minutes",input.min_minutes);
    if (packages.length > 0) {
      setBtn(true);
      fareEditApi({ id: input?.id, payload: formData })
        .then((res) => {
          console.log(res,"kkk");
          setBtn(false);
          toast.success(res?.message, {
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            autoClose: 2000,
          });
          popUpCancel(); // Close the modal
          getList(); // Refresh the fare list
        })
        .catch((err) => {
          setBtn(false);
          toast.error("Failed to edit fare", {
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            autoClose: 2000,
          });
        });
    } else {
      toast.error("Please Add All Details", {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        autoClose: 2000,
      });
    }
  };
  

  const deleteFare = (id) => {
    Swal.fire({
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete!",
    }).then((result) => {
      if (result.isConfirmed) {
        setBtn(true);
        deleteFareApi(id)
          .unwrap()
          .then((res) => {
            setBtn(false);
            popUpCancel();
            Swal.fire("Delete", "Delete Successfully.", "success");
            getList();
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      }
    });
  };

  const popUpCancel = () => {
    setEditModal(false);
    setAddModal(false);
    setInput({});
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <>
      <aside>
        <article>
          <div className="p-3">
            <Tabs
              defaultActiveKey="Oneway"
              id="uncontrolled-tab-example"
              className="mb-3 tab-appoinment w-100"
            >
              <Tab eventKey="Oneway" title="One way">
                <OneWay
                  addFare={addFare}
                  setEditModal={setEditModal}
                  setAddModal={setAddModal}
                  addModal={addModal}
                  btn={btn}
                  editModal={editModal}
                  handleChange={handleChange}
                  input={input}
                  listOneWay={listOneWay}
                  viewFare={viewFare}
                  editFare={editFare}
                  deleteFare={deleteFare}
                  popUpCancel={popUpCancel}
                />
              </Tab>
              <Tab eventKey="Roun" title="Round">
                <Roundard
                  addFare={addFare}
                  setEditModal={setEditModal}
                  setAddModal={setAddModal}
                  addModal={addModal}
                  btn={btn}
                  editModal={editModal}
                  handleChange={handleChange}
                  input={input}
                  listRoundard={listRoundard}
                  viewFare={viewFare}
                  editFare={editFare}
                  deleteFare={deleteFare}
                  popUpCancel={popUpCancel}
                />
              </Tab>

              <Tab eventKey="Out station one" title="Out station One Way">
                <Outstation
                  addFare={addFare}
                  setEditModal={setEditModal}
                  setAddModal={setAddModal}
                  addModal={addModal}
                  btn={btn}
                  editModal={editModal}
                  handleChange={handleChange}
                  input={input}
                  listOutStation={listOutStation}
                  viewFare={viewFare}
                  editFare={editFare}
                  deleteFare={deleteFare}
                  popUpCancel={popUpCancel}
                />
              </Tab>

              <Tab eventKey="Out station round" title="Out station Round">
                <OutstationRoundard
                  addFare={addFare}
                  setEditModal={setEditModal}
                  setAddModal={setAddModal}
                  addModal={addModal}
                  btn={btn}
                  editModal={editModal}
                  handleChange={handleChange}
                  input={input}
                  listOutStationRoundard={listOutStationRoundard}
                  viewFare={viewFare}
                  editFare={editFare}
                  deleteFare={deleteFare}
                  popUpCancel={popUpCancel}
                />
              </Tab>
            </Tabs>
          </div>
        </article>
      </aside>
    </>
  );
}

export default FareSetting;
