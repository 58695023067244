import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";

import { GlobalFilter } from "../Roles/GlobalFilter";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  useLazyOtherChargeListQuery,
  useOtherChargeDeleteMutation,
} from "../../../constants/redux/api/api";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead className="text-center">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="text-center">
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
    </>
  );
}

const OtherCharge = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const Id = location?.state?.id;
  const type = location?.state?.type;
  const permissons = JSON.parse(localStorage.getItem("role"));

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [input, setInput] = useState([]);
  const [sub, setSub] = useState();

  const [list, setList] = useState([]);
  const [btn, setBtn] = useState(false);

  // RTK QUERY
  const [otherChargeListApi] = useLazyOtherChargeListQuery();
  const [otherChargeDeleteApi] = useOtherChargeDeleteMutation();

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: "Type",
        accessor: "type",
        Cell: ({ value, row }) => {
          return <span>{value}</span>;
        },
      },

      {
        Header: "Hour",
        accessor: "hour",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Price",
        accessor: "amount",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              {/* {permissons?.includes("CAN-EDIT-FARE PRICE") ? ( */}
              <button
                className="comn-btn bg-primary edit-btn-svg"
                onClick={() => setEditModal(true)}
              >
                <FontAwesomeIcon icon={faPen} />
              </button>

              {/* ) : null} */}
            </React.Fragment>
          );
        },
      },
    ],
    []
  );

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setInput((val) => ({ ...val, [name]: value }));
  };

  const addtax = () => {
    let formData = new FormData();
    formData.append("value", input?.value);
    formData.append("type", input?.type);
    setAddModal(false);
    // setBtn(true);
    // addSettingApi(formData)
    //   .unwrap()
    //   .then((res) => {
    //     setBtn(false);
    //     setAddModal(false);
    //     toast.success(res?.message, {
    //       position: "top-right",
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //       autoClose: 2000,
    //     });
    //     getList();
    //     navigate("/setting");
    //   })
    //   .catch((err) => {
    //     setBtn(false);
    //   });
  };

  const editTax = () => {
    let formData = new FormData();
    formData.append("value", input?.value);
    formData.append("type", input?.type);
    formData.append("_method", "PUT");
    setEditModal(false);
    // setBtn(true);
    // updateSettingApi({ id: Id, payload: formData })
    //   .then((res) => {
    //     // if (res?.status == "success") {
    //     toast.success(res?.message, {
    //       position: "top-right",
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined,
    //       autoClose: 2000,
    //     });
    //     setId("");
    //     setBtn(false);
    //     setEditModal(false);
    //     getList();
    //     navigate("/setting");
    //     // } else {
    //     //   setBtn(false);
    //     // }
    //   })
    //   .catch((err) => {
    //     setBtn(false);
    //   });
  };

  const getList = () => {
    otherChargeListApi()
      .unwrap()
      .then((res) => {
        let responce = res?.other_charges?.filter((i) => i?.type == 1);
        // console.log("responce", responce);
        setList(responce);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const deleteOther = (id) => {
    Swal.fire({
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete!",
    }).then((result) => {
      if (result.isConfirmed) {
        setBtn(true);
        otherChargeDeleteApi(id)
          .unwrap()
          .then((res) => {
            setBtn(false);
            Swal.fire("Delete", "Delete Successfully.", "success");
            getList();
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      }
    });
  };

  const data = [
    {
      id: 1,
      hour: "1hrs",
      type: "One way",
      amount: 20,
    },
    {
      id: 2,
      hour: "1.30hrs",
      type: "Round",
      amount: 25,
    },
    {
      id: 3,
      hour: "2hrs",
      type: "Out station",
      amount: 30,
    },
  ];

  useEffect(() => {
    // getList();
    setList(data);
  }, []);

  // console.log("type", type);

  return (
    <aside>
      <article className="p-3">
        <div className="p-2 bg-white rounded-2">
          <div className="ad-btn w-100 flex-ac-jb p-2 d-flex justify-content-end">
            <div className="flex-ac-jc">
              {permissons?.includes("CAN-ADD-FARE PRICE") && type !== "view" ? (
                <button
                  onClick={() =>
                    navigate("/setting/add-fare-setting", {
                      state: {
                        type: "addDistanceFare",
                        ride_id: Id,
                      },
                    })
                  }
                  className="comn-btn bg-primary1 px-3 py-2"
                >
                  Add Base Fare
                </button>
              ) : null}
            </div>
          </div>
          <Table columns={columns} data={list} />
        </div>
      </article>

      <Modal
        className="table-modal"
        show={editModal}
        onHide={() => setEditModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="px-2 py-2 col-12 d-flex flex-wrap">
          <div className="px-1 col-sm-12 modal-label-input">
            <h4>Edit Distance</h4>
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Type
            </label>
            <select
              name="type"
              id=""
              onChange={(e) => {
                handleChange(e);
                setSub(e.target.value);
              }}
              value={"1"}
            >
              <option>Select Type</option>
              <option value={"1"}>One way</option>
              <option value={"2"}>Round</option>
              <option value={"3"}>Out station</option>
            </select>
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Hour
            </label>
            <input
              type="text"
              id="example-if-email"
              name="value"
              onChange={handleChange}
              placeholder="value"
              value={input?.value}
            />
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Price
            </label>
            <input
              type="text"
              id="example-if-email"
              name="price"
              onChange={handleChange}
              placeholder="price"
              value={input?.price}
            />
          </div>
        </div>
        <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
          <button
            onClick={() => {
              setEditModal(false);
            }}
            className="comn-btn bg-hash2 px-3 py-2 me-2"
          >
            Cancel
          </button>
          <button
            type="button"
            className="comn-btn bg-success px-3 py-2 me-2"
            data-dismiss="modal"
            onClick={editTax}
            disabled={btn ? true : false}
          >
            Submit
          </button>
        </div>
      </Modal>
    </aside>
  );
};

export default OtherCharge;
