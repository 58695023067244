import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faEye } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { bannerList, maleAvatar } from "../../../assets/img";
import Pagination from "react-bootstrap/Pagination";
import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const DriverHistory = () => {
  const navigate = useNavigate();

  const data = [
    {
      id: 1,
      name: "person1",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 2,
      name: "person2",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 3,
      name: "person3",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 4,
      name: "person4",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 5,
      name: "person5",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 6,
      name: "person6",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 7,
      name: "person7",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 8,
      name: "person8",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 9,
      name: "person9",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 10,
      name: "person10",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 11,
      name: "person11",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 12,
      name: "person12",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 13,
      name: "person13",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 14,
      name: "person14",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
    {
      id: 15,
      name: "person15",
      from: "vadapalani",
      to: "kodambakkam",
      cost: "50rs",
      review: "****",
    },
  ];

  const [adminData, setadminData] = useState(data);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [currentItems, setCurrentItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [counter, setCounter] = useState(0);
  const [last, setLast] = useState(10);
  const [itemOffset, setItemOffset] = useState(0);

  const [searcharr, setSearchArr] = useState("");

  useEffect(() => {
    let temp = [...adminData];

    if (searcharr == "") {
      const endOffset = itemOffset + itemsPerPage;

      setCurrentItems(adminData?.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(adminData?.length / itemsPerPage));
    } else {
      let datas = temp?.filter((val) =>
        val?.name?.toLowerCase().includes(searcharr.toLowerCase())
      );

      setCurrentItems(datas);
    }
  }, [searcharr]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;

    setCurrentItems(adminData?.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(adminData?.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, adminData]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % adminData?.length;
    setItemOffset(newOffset);

    let temp2 = event.selected;
    setCounter(temp2);

    let temp = event.selected + 1 + "0";
    setLast(temp);
  };

  const setListItems = (e) => {
    const value = e.target.value;

    setItemsPerPage(parseInt(value));
  };
  const location = useLocation();

  console.log(location?.state?.id);

  return (
    <aside>
      <TopHeader type="driver-history" />
      <article className="p-3">
        <article>
          <div className="p-3">
            <div className="p-3 bg-white rounded-2">
              <div className="flex-ac-jb">
                <p
                  className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                  for=""
                >
                  Driver1
                </p>
                <img className="avatar-img" src={maleAvatar} />
              </div>
              <Form className="d-flex flex-wrap">
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        User Name
                      </label>
                      <input
                        id="example-if-email"
                        name="Phone_Number"
                        placeholder="Phone Number"
                        className="bg-transparent"
                        type="text"
                        value="User1"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        From
                      </label>
                      <input
                        type="email"
                        id="example-if-email"
                        placeholder="Email"
                        className="bg-transparent"
                        value="Vadapalani"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        To
                      </label>
                      <input
                        placeholder="aadhaar number"
                        type="text"
                        name="aadhaar number"
                        className="bg-transparent"
                        value="Kodambakkam"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Date
                      </label>
                      <input
                        type="text"
                        name="license-number"
                        id="example-if-email"
                        placeholder="license number"
                        className="bg-transparent"
                        value="22/02/2023"
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Ride Cost
                      </label>
                      <input
                        placeholder="vehicle number"
                        className="bg-transparent"
                        type="text"
                        value="50 rs"
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Payment Method
                      </label>
                      <input
                        type="text"
                        id="example-if-email"
                        placeholder="rc number"
                        className="bg-transparent"
                        value="UPI"
                        disabled
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Driver Review
                      </label>
                      <input
                        placeholder="address"
                        type="text"
                        name="address"
                        className="bg-transparent"
                        value="****"
                      />
                    </div>
                  </div>
                </div> */}

                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        User Review
                      </label>
                      <input
                        type="text"
                        name="city"
                        className="bg-transparent"
                        placeholder="city"
                        value={"*****"}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Feedback
                      </label>
                      <p className="address-request textarea-height" disabled>
                        {
                          "This Ape auto is powerful. Piaggio offered good features, the drving of this auto is smooth. Easy on/off and steering handle is also smooth. The driver seat is wide and big, bigger windshield makes visibility clear. Overall super vehicle for last-mile auto rickshaw."
                        }
                      </p>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </article>
      </article>
    </aside>
  );
};

export default DriverHistory;
