import React from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import SignIn from "./views/auth/SignIn";
import "./App.scss";
import "./responsive.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import Dashboard from "./views/screens/Dashboard/dashboard";
import Layout from "./Layout/Layout";
import AdminPage from "./views/screens/Admin/admin";
import Roles from "./views/screens/Roles/RolesTest";
import Drivers from "./views/screens/Drivers/drivers";
import Users from "./views/screens/Users/users";
import Booking from "./views/screens/Booking/Booking";
import AddRoles from "./views/screens/Roles/addRoles";
import AddDriver from "./views/screens/Drivers/driverProfile";
import DriverDocument from "./views/screens/Driver_Request/driver-document";
import DriverRequest from "./views/screens/Driver_Request/driver-request";
import DriverHistory from "./views/screens/Drivers/driverHistory";
import UserProfile from "./views/screens/Users/userProfile";
import UserHistory from "./views/screens/Users/userHistory";
import BookingHistory from "./views/screens/Booking/viewBooking";
import Coupon from "./views/screens/Coupons/coupon";
import AddCoupon from "./views/screens/Coupons/addCoupon";
import Subscription from "./views/screens/Subscription/subscription";
import HelpSupport from "./views/screens/Help&Support/helpAndSupport";
import ReferEarn from "./views/screens/Refer_and_Earn/driver";
import Setting from "./views/screens/Settings/setting";
import CancelRide from "./views/screens/Cancel_Ride/cancel_ride";
import DriversActive from "./views/screens/ActiveLog/activeLog";
import AddSetting from "./views/screens/Settings/addSetting";
import PushNotification from "./views/screens/Notification/pushNotification";
import OfflineBooking from "./views/screens/Offline_Ride/offlineBooking";
import AddOfflineBooking from "./views/screens/Offline_Ride/addOfflineBooking";
import ScheduleRide from "./views/screens/ScheduleRide/scheduleRideTest";
import ViewScheduleRide from "./views/screens/ScheduleRide/viewScheduleRide";
import PayTax from "./views/screens/Pay_Tax/payTax";
import ForgotPassword from "./views/auth/forgotPassword";
import ResetPassword from "./views/auth/resetPassword";
import Subscribtion_Detailed_View from "./views/screens/Subscription/Subscribtion_Detailed_View";
import ActiveLogList from "./views/screens/ActiveLog/ActiveLogList";
import ReportCopy from "./views/screens/Report/report copy";
import AddFareSetting from "./views/screens/Settings/addFare";
import FourNotFourPage from "./views/errorScreens/404";
import FourHandredPage from "./views/errorScreens/400";
import ViewRides from "./views/screens/Cancel_Ride/viewRides";
import Notification from "./views/screens/Notification/notification";
import AddAdmin from "./views/screens/Admin/addAdmin";
import ChangePassword from "./views/auth/changePassword";
import AddZone from "./views/screens/Settings/addZone";
import AddSubscription from "./views/screens/Subscription/addSubscription";
import OfflineHistory from "./views/screens/Offline_Ride/viewOfflineBooking";
import Invoice from "./views/screens/Booking/invoice";
import BookingList from "./views/screens/Booking/BookingList";
import AddPeak from "./views/screens/Settings/addPeak";
import PrivateRoutes from "./constants/PrivateRoutes";
import useToken from "./constants/Storage/useToken";
import useUser from "./constants/Storage/userData";
import Vehicles from "./views/screens/Vehicle_Category/Vehicles";
import AddVehicle from "./views/screens/Vehicle_Category/addVehicle";
import RevenueList from "./views/screens/Revenue/RevenueList";
import SOS from "./views/screens/Review&Ratings/SOS";
import CarList from "./views/screens/CarType/CartList";
import Rewards from "./views/screens/Rewards/Rewards";
import Reward from "./views/screens/Rewards/Reward";
import FaqList from "./views/screens/Faq/FaqList";
import Faqadd from "./views/screens/Faq/FaqAdd";
import Insurance from "./views/screens/Settings/Insurance";
export const ReactRoute = () => {
  const { token } = useToken();
  const { user } = useUser();
  return (
    <Routes>
      <Route path="/login" index element={<SignIn />} />
      <Route path="/forgot-password" index element={<ForgotPassword />} />
      <Route path="/reset-password" index element={<ResetPassword />} />
      <Route path="/change-password" index element={<ChangePassword />} />
      <Route element={<PrivateRoutes token={token} />}>
        <Route
          path="/booking/view-booking/invoice"
          index
          element={<Invoice />}
        />
        <Route element={<Layout />}>
          <Route path="/" index element={<Dashboard />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/Admin" index element={<AdminPage />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/Admin/add-admin" index element={<AddAdmin />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/role" index element={<Roles />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/roles/add-roles" index element={<AddRoles />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/driver" index element={<Drivers />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/driver/view-profile" index element={<AddDriver />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/driver/view-profile/history"
            index
            element={<DriverHistory />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route path="/driver-request" index element={<DriverRequest />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/driver-request/document"
            index
            element={<DriverDocument />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route path="/user" index element={<Users />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/user/view-profile" index element={<UserProfile />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/user/view-profile/history"
            index
            element={<UserHistory />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route path="/booking" index element={<BookingList />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/booking/view-booking"
            index
            element={<BookingHistory />}
          />
        </Route>
        <Route
          path="/booking/view-booking/invoice"
          index
          element={<Invoice />}
        />
        <Route element={<Layout />}>
          <Route path="/coupon" index element={<Coupon />} />
          <Route path="/rewards" index element={<Rewards />} />
          <Route path="/faqsadd" index element={<Faqadd/>}/>
          <Route path="/faqs" index element={<FaqList/>} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/coupon/add-coupon" index element={<AddCoupon />} />
          <Route path="/rewards/reward" index element={<Reward />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/subscription" index element={<Subscription />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/subscription/detail-view"
            index
            element={<Subscribtion_Detailed_View />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route path="/subscription-add" index element={<AddSubscription />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/help-support" index element={<HelpSupport />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/refer-earn" index element={<ReferEarn />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/setting" index element={<Setting />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/setting/add-setting" index element={<AddSetting />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/setting/add-zone" index element={<AddZone />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/setting/add-fare-setting"
            index
            element={<AddFareSetting />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route path="/setting/add-peak-setting" index element={<AddPeak />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/cancelled" index element={<CancelRide />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/cancelled/view" index element={<ViewRides />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/activeLog" index element={<DriversActive />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/activeLog/view-list"
            index
            element={<ActiveLogList />}
          />
        </Route>
        <Route element={<Layout />}>
          {/* <Route path="/report" index element={<Report />} /> */}
          <Route path="/report" index element={<ReportCopy />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/sos" index element={<SOS />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/push-notification"
            index
            element={<PushNotification />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route path="/revenue-list" index element={<RevenueList />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/notification" index element={<Notification />} />
        </Route>

        <Route element={<Layout />}>
          <Route path="/offline-booking" index element={<OfflineBooking />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/offline-booking/add-booking"
            index
            element={<AddOfflineBooking />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route path="/carType" index element={<CarList />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/offline-booking/view-booking"
            index
            element={<OfflineHistory />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route path="/schedule-ride" index element={<ScheduleRide />} />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/schedule-ride/view"
            index
            element={<ViewScheduleRide />}
          />
        </Route>
        <Route element={<Layout />}>
          <Route path="/pay-tax" index element={<PayTax />} />
        </Route>

        <Route element={<Layout />}>
          <Route path="/vehicle-category" index element={<Vehicles />} />
        </Route>
        <Route element={<Layout />}>
          <Route path="/vehicle" index element={<AddVehicle />} />
        </Route>
      </Route>

      <Route path="*" index element={<FourNotFourPage />} />
      <Route path="/page-not-found" index element={<FourHandredPage />} />
    </Routes>
  );
};
