import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useState } from "react";
import TopHeader from "../../../component/TopHeader";
import { useLocation, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import swal from "sweetalert";
import { toast } from "react-toastify";
import {
  useLazyPeakViewQuery,
  usePeakAddMutation,
  usePeakEditMutation,
} from "../../../constants/redux/api/api";

const AddPeak = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state.type;
  const ride_id = location?.state.ride_id;
  const lg_fairtype = location?.state.fair_type;

  // localStorage.setItem("fairType", location?.state.fair_type);
  // const lg_fairtype = localStorage.getItem("fairType");
  const Id = location?.state.id;
  // console.log("location", location);
  // console.log("lg_fairtype", lg_fairtype);
  // console.log("Id", Id);

  const [base, setBase] = useState([]);
  const [distance, setDistance] = useState([]);
  const [wait, setWait] = useState([]);
  const [peak, setPeak] = useState([]);
  const [night, setNigt] = useState([]);
  const [btn, setBtn] = useState(false);
  const [btnTo, setBtnTo] = useState("");

  // HANDLE CHANGE EVENT

  const handleChangePeak = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setPeak((val) => ({ ...val, [name]: value }));
  };

  const handleChangeNight = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setNigt((val) => ({ ...val, [name]: value }));
  };

  // RTK QUERY
  const [peakAddAPi] = usePeakAddMutation();
  const [peakEditAPi] = usePeakEditMutation();
  const [peakViewAPi] = useLazyPeakViewQuery();

  // PEAK ADD & EDIT

  const peakAdd = () => {
    let peakFare = {
      from_time: peak?.from_time,
      to_time: peak?.to_time,
      percentage: peak?.percentage,
      ride_type_id: ride_id,
      type: 1,
    };
    if (Id) {
      peakFare._method = "PUT";
      setBtn(true);
      peakEditAPi({ id: Id, payload: peakFare })
        .unwrap()
        .then((res) => {
          setBtn(false);
          toast.success(res?.message, {
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            autoClose: 2000,
          });
          navigate(-1);
        })
        .catch((err) => {
          console.log("err", err);
          setBtn(false);
        });
    } else {
      setBtn(true);
      peakAddAPi(peakFare)
        .unwrap()
        .then((res) => {
          setBtn(false);
          toast.success(res?.message, {
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            autoClose: 2000,
          });
          navigate(-1);
        })
        .catch((err) => {
          console.log("err", err);
          setBtn(false);
        });
    }
  };

  // NIGHT PEAK ADD & EDIT

  const nightAdd = () => {
    let nightFare = {
      from_time: night?.from_time,
      to_time: night?.to_time,
      percentage: night?.percentage,
      ride_type_id: ride_id,
      type: 2,
    };

    if (Id) {
      nightFare._method = "PUT";
      setBtn(true);
      peakEditAPi({ id: Id, payload: nightFare })
        .unwrap()
        .then((res) => {
          setBtn(false);
          toast.success(res?.message, {
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            autoClose: 2000,
          });
          navigate(-1);
        })
        .catch((err) => {
          console.log("err", err);
          setBtn(false);
        });
    } else {
      setBtn(true);
      peakAddAPi(nightFare)
        .unwrap()
        .then((res) => {
          setBtn(false);
          toast.success(res?.message, {
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            autoClose: 2000,
          });
          navigate(-1);
        })
        .catch((err) => {
          console.log("err", err);
          setBtn(false);
        });
    }
  };

  //  VIEW

  const viewList = () => {
    let peakFaredata = {};
    let nightFaredata = {};
    peakViewAPi(Id)
      .unwrap()
      .then((res) => {
        if (res?.peak_charge?.type == 1) {
          peakFaredata.from_time = res?.peak_charge?.from_time;
          peakFaredata.to_time = res?.peak_charge?.to_time;
          peakFaredata.percentage = res?.peak_charge?.percentage;
          setPeak(peakFaredata);
        } else if (res?.peak_charge?.type == 2) {
          nightFaredata.from_time = res?.peak_charge?.from_time;
          nightFaredata.to_time = res?.peak_charge?.to_time;
          nightFaredata.percentage = res?.peak_charge?.percentage;
          setNigt(nightFaredata);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (Id) {
      viewList();
    }
  }, []);

  return (
    <>
      <aside>
        {type === "edit" ? (
          <TopHeader type="editFare" />
        ) : (
          <TopHeader type="addFare" />
        )}

        <article>
          <Tabs
            defaultActiveKey={lg_fairtype == "Peak" ? "Detail-View" : "Tax"}
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab eventKey="Detail-View" title="Peak Fare">
              <div className="p-3">
                <div className="p-3 bg-white rounded-2">
                  <label>Peak Fare test</label>
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Start Time
                          </label>
                          <input
                            type="time"
                            id="example-if-email"
                            placeholder="start time"
                            name="from_time"
                            className="bg-transparent"
                            onChange={handleChangePeak}
                            value={peak?.from_time}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            End Time
                          </label>
                          <input
                            type="time"
                            id="example-if-email"
                            placeholder="end time"
                            name="to_time"
                            onChange={handleChangePeak}
                            value={peak?.to_time}
                            className="bg-transparent"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Peak Fare
                          </label>
                          <input
                            type="text"
                            id="example-if-email"
                            placeholder="peak fare"
                            name="percentage"
                            onChange={handleChangePeak}
                            value={peak?.percentage}
                            className="bg-transparent"
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                  <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
                    <button
                      className="comn-btn bg-primary px-3 py-2 me-2"
                      onClick={peakAdd}
                      disabled={btn ? true : false}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="Tax" title="Night Fare">
              <div className="p-3">
                <div className="p-3 bg-white rounded-2">
                  <label>Night Fare</label>
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Start Time
                          </label>
                          <input
                            type="time"
                            id="example-if-email"
                            placeholder="start time"
                            name="from_time"
                            onChange={handleChangeNight}
                            value={night?.from_time}
                            className="bg-transparent"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            End Time
                          </label>
                          <input
                            type="time"
                            id="example-if-email"
                            placeholder="end time"
                            name="to_time"
                            onChange={handleChangeNight}
                            value={night?.to_time}
                            className="bg-transparent"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Night Fare
                          </label>
                          <input
                            type="text"
                            id="example-if-email"
                            placeholder="night fare"
                            name="percentage"
                            onChange={handleChangeNight}
                            value={night?.percentage}
                            className="bg-transparent"
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                  <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
                    <button
                      className="comn-btn bg-primary px-3 py-2 me-2"
                      onClick={nightAdd}
                      disabled={btn ? true : false}
                    >
                      Submit
                    </button>
                  </div>
                  <br></br>
                </div>
              </div>
            </Tab>
          </Tabs>
        </article>
      </aside>
    </>
  );
};

export default AddPeak;
