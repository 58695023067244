import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";

import { GlobalFilter } from "../Roles/GlobalFilter";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  useCarAddMutation,
  useCarDeleteMutation,
  useCarEditMutation,
  useLazyCarListQuery,
  useLazyCarViewQuery,
  useLazyOtherChargeListQuery,
  useOtherChargeDeleteMutation,
} from "../../../constants/redux/api/api";
import { icon } from "leaflet";
import { imgNotava } from "../../../assets/img";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      <Row className="mb-3 table-show">
        <Col className="d-flex align-items-center">
          <p>Show</p>

          <select
            className="form-control w-auto mx-2 p-2 py-1"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <p>entries</p>
        </Col>
        <Col className="d-flex justify-content-end">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {/* <Link to="/support/add-support">
              <Button style={{ background: 'rgb(95,188,95)' }} className="btn-sm btn-round has-ripple ml-2 border-0">
                <i className="feather icon-plus" /> Add Ticket
              </Button>
            </Link> */}
        </Col>
      </Row>
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      {/* <Row className="pagebtm justify-content-between table-show my-2"> */}
      <Row className=" justify-content-between table-show my-2">
        <Col>
          <span className="d-flex align-items-center">
            <p className="px-1">Page</p>{" "}
            <strong>
              {" "}
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>{" "}
            <p className="px-1">|</p>
            <p className="px-1">Go to page:</p>{" "}
            <input
              type="number"
              className="form-control ml-2  p-1 py-1 text-center"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "40px" }}
            />
          </span>
        </Col>
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
    </>
  );
}

const CarList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const Id = location?.state?.id;
  const type = location?.state?.type;
  const permissons = JSON.parse(localStorage.getItem("role"));

  const [addModal, setAddModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const [input, setInput] = useState([]);
  const [sub, setSub] = useState();

  const [list, setList] = useState([]);
  const [btn, setBtn] = useState(false);
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [name, setName] = useState("");
  const [Icon, setIcon] = useState();
  const [nameErr, setNameErr] = useState(false);
  const [IconErr, setIconErr] = useState(false);
  const [editId, setEditId] = useState("");

  const startDate = (e) => {
    setFrom(e.target.value);
    setTo("");
  };

  const ToDate = (e) => {
    setTo(e.target.value);
  };

  // RTK QUERY
  const [otherChargeListApi] = useLazyCarListQuery();
  const [cartAddApi] = useCarAddMutation();
  const [cartEditApi] = useCarEditMutation();
  const [deleteCartApi] = useCarDeleteMutation();
  const [cartViewApi] = useLazyCarViewQuery();

  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },

      {
        Header: "Name",
        accessor: "name",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Icon",
        accessor: "img_url",
        Cell: ({ value, row }) => {
          return <img src={value} width={45} />;
        },
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              {/* {permissons?.includes("CAN-EDIT-FARE PRICE") ? ( */}
              <button
                className="comn-btn bg-primary edit-btn-svg"
                onClick={() => {
                  setEditId(row.original.id);
                  getView(row.original.id);
                }}
              >
                <FontAwesomeIcon icon={faPen} />
              </button>

              <button
                className="comn-btn bg-danger edit-btn-svg ms-4"
                onClick={() => deleteOther(row.original.id)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </button>
              {/* ) : null} */}
            </React.Fragment>
          );
        },
      },
    ],
    []
  );

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setInput((val) => ({ ...val, [name]: value }));
  };

  const addtax = () => {
    if (name?.length == "" || Icon?.length == "") {
      setNameErr(true);
      setIconErr(true);
    } else {
      let formData = new FormData();
      formData.append("name", name);
      if (Icon?.name) {
        formData.append("icon", Icon);
      }
      setBtn(true);
      cartAddApi(formData)
        .unwrap()
        .then((res) => {
          setBtn(false);
          setAddModal(false);
          toast.success(res?.message, {
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            autoClose: 2000,
          });
          getList();
          setAddModal(false);
        })
        .catch((err) => {
          setBtn(false);
          setAddModal(false);
          console.log(err);
        });
    }
  };

  const editTax = (id) => {
    // console.log(id);

    if (name?.length == "" || Icon?.length == "") {
      setNameErr(true);
      setIconErr(true);
    } else {
      let formData = new FormData();
      formData.append("name", name);
      if (Icon?.name) {
        formData.append("icon", Icon);
      }
      formData.append("_method", "PUT");
      setEditModal(false);
      setBtn(true);
      cartEditApi({ id: editId, payload: formData })
        .then((res) => {
          // if (res?.status == "success") {
          toast.success(res?.message, {
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            autoClose: 2000,
          });
          setBtn(false);
          setEditModal(false);
          getList();

          setBtn(false);
        })
        .catch((err) => {
          setBtn(false);
          console.log(err);
        });
    }
  };

  const getList = () => {
    otherChargeListApi()
      .unwrap()
      .then((res) => {
        if (Array.isArray(res?.car_types)) {
          setList(res?.car_types);
        } else {
          setList([]);
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const getView = (id) => {
    cartViewApi(id)
      .unwrap()
      .then((res) => {
        console.log(res, "res");
        const data = res?.car_type;
        setName(data?.name);
        setIcon(data?.img_url);
        setEditModal(true);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const deleteOther = (id) => {
    Swal.fire({
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete!",
    }).then((result) => {
      if (result.isConfirmed) {
        setBtn(true);
        deleteCartApi(id)
          .unwrap()
          .then((res) => {
            setBtn(false);
            Swal.fire("Delete", "Delete Successfully.", "success");
            getList();
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      }
    });
  };

  const data = [
    {
      id: 1,
      hour: "1hrs",
      type: "One way",
      amount: 20,
    },
    {
      id: 2,
      hour: "1.30hrs",
      type: "Round",
      amount: 25,
    },
    {
      id: 3,
      hour: "2hrs",
      type: "Out station",
      amount: 30,
    },
  ];

  useEffect(() => {
    getList();
  }, []);

  // console.log("type", type);

  return (
    <aside>
      <TopHeader type="carType" />
      <article className="p-3">
        <div className="p-2 bg-white rounded-2">
          {/* <div className="ad-btn w-100 flex-ae-jb px-0 py-2">
            <Col md={3} xs={6} className="px-2 my-2">
              <span>From Date</span>
              <input
                type="date"
                className="form-control"
                onChange={startDate}
                value={from}
              />
            </Col>
            <Col md={3} xs={6} className="px-2 my-2">
              <span>To Date</span>
              <input
                type="date"
                className="form-control"
                onChange={ToDate}
                value={to}
              />
            </Col>
          </div> */}
          <div className="ad-btn w-100 flex-ac-jb p-2 d-flex justify-content-end">
            <div className="flex-ac-jc">
              <button
                onClick={() => setAddModal(true)}
                className="comn-btn bg-primary1 px-3 py-2"
              >
                Add Car Type
              </button>
            </div>
          </div>
          <Table columns={columns} data={list} />
        </div>
      </article>

      <Modal
        className="table-modal"
        show={addModal}
        onHide={() => setAddModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="px-2 py-2 col-12 d-flex flex-wrap">
          <div className="px-1 col-sm-12 modal-label-input">
            <h4>Add Car Type</h4>
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Name
            </label>
            <input
              type="text"
              id="example-if-email"
              name="value"
              onChange={(e) => setName(e.target.value)}
              placeholder="Name"
              value={name}
            />
            {name?.length == 0 && nameErr && (
              <p className="err-p">Enter Name</p>
            )}
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Icon
            </label>
            <input
              type="file"
              id="example-if-email"
              name="price"
              onChange={(e) => setIcon(e.target.files[0])}
              placeholder="Icon"
            />
            {Icon?.length == 0 && IconErr && (
              <p className="err-p">Enter Icon</p>
            )}
          </div>
          <div className="px-1 col-sm-6 modal-label-input"></div>
          <div className="px-1 col-sm-6 modal-label-input">
            <img
              src={
                Icon?.name ? URL.createObjectURL(Icon) : Icon ? Icon : imgNotava
              }
              width={150}
              height={150}
            />
          </div>
        </div>
        <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
          <button
            onClick={() => {
              setAddModal(false);
            }}
            className="comn-btn bg-hash2 px-3 py-2 me-2"
          >
            Cancel
          </button>
          <button
            type="button"
            className="comn-btn bg-success px-3 py-2 me-2"
            data-dismiss="modal"
            onClick={addtax}
            disabled={btn ? true : false}
          >
            Submit
          </button>
        </div>
      </Modal>

      <Modal
        className="table-modal"
        show={editModal}
        onHide={() => setEditModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="px-2 py-2 col-12 d-flex flex-wrap">
          <div className="px-1 col-sm-12 modal-label-input">
            <h4>Edit Car Type</h4>
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Name
            </label>
            <input
              type="text"
              id="example-if-email"
              name="value"
              onChange={(e) => setName(e.target.value)}
              placeholder="name"
              value={name}
            />
            {name?.length == 0 && nameErr && (
              <p className="err-p">Enter Name</p>
            )}
          </div>

          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Icon
            </label>
            <input
              type="file"
              id="example-if-email"
              name="price"
              onChange={(e) => setIcon(e.target.files[0])}
              placeholder="price"
            />
            {Icon?.length == 0 && IconErr && (
              <p className="err-p">Enter Icon</p>
            )}
          </div>
          <div className="px-1 col-sm-6 modal-label-input"></div>
          <div className="px-1 col-sm-6 modal-label-input">
            <img
              src={
                Icon?.name ? URL.createObjectURL(Icon) : Icon ? Icon : imgNotava
              }
              width={150}
              height={150}
            />
          </div>
        </div>
        <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
          <button
            onClick={() => {
              setEditModal(false);
            }}
            className="comn-btn bg-hash2 px-3 py-2 me-2"
          >
            Cancel
          </button>
          <button
            type="button"
            className="comn-btn bg-success px-3 py-2 me-2"
            data-dismiss="modal"
            onClick={editTax}
            disabled={btn ? true : false}
          >
            Submit
          </button>
        </div>
      </Modal>
    </aside>
  );
};

export default CarList;
