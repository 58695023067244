import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useState } from "react";
import TopHeader from "../../../component/TopHeader";
import { useLocation, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import swal from "sweetalert";
import { toast } from "react-toastify";
import {
  useFareAddMutation,
  useFareEditMutation,
  useLazyFareViewQuery,
  useLazyOtherChargeViewQuery,
  useOtherChargeAddMutation,
  useOtherChargeEditMutation,
} from "../../../constants/redux/api/api";

const AddFareSetting = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const type = location?.state.type;
  const ride_id = location?.state.ride_id;
  const lg_fairtype = location?.state.fair_type;
  // localStorage.setItem("fairType", location?.state.fair_type);
  // const lg_fairtype = localStorage.getItem("fairType");
  const Id = location?.state.id;
  // console.log("location", location);
  // console.log("lg_fairtype", lg_fairtype);
  // console.log("Id", Id);

  const [base, setBase] = useState([]);
  const [distance, setDistance] = useState([]);
  const [wait, setWait] = useState([]);
  const [peak, setPeak] = useState([]);
  const [night, setNigt] = useState([]);
  const [btn, setBtn] = useState(false);
  const [btnTo, setBtnTo] = useState("");

  // HANDLE CHANGE EVENT

  const handleChangeBase = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setBase((val) => ({ ...val, [name]: value }));
  };

  const handleChangeDistance = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setDistance((val) => ({ ...val, [name]: value }));
  };

  const handleChangeWaiting = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setWait((val) => ({ ...val, [name]: value }));
  };

  const handleChangePeak = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setPeak((val) => ({ ...val, [name]: value }));
  };

  const handleChangeNight = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setNigt((val) => ({ ...val, [name]: value }));
  };

  // const add = (num) => {
  // let formData = new FormData()
  //   if (num == 1) {
  //     console.log("1", num);
  //     formData.append("from", input?.from);
  //     formData.append("to", input?.to);
  //     formData.append("amount", input?.amount);

  //     if (Id) {
  //       formData.append("_method", "PUT");
  //       api.updateFare(Id, formData).then((res) => {
  //         if (res?.status == "success") {
  //           swal(res?.message, "", "success");
  //           navigate("/setting");
  //         }
  //       });
  //     } else if (!Id) {
  //       api.addFare(formData).then((res) => {
  //         if (res?.status == "success") {
  //           swal(res?.message, "", "success");
  //           navigate("/setting");
  //         }
  //       });
  //     }
  //   } else if (num == 2) {
  //     console.log("2", num);

  //     formData.append("min_km_time", input?.min_km_time);
  //     formData.append("amount", input?.amount);
  //     formData.append("type", 1);

  //     if (Id) {
  //       formData.append("_method", "PUT");
  //       api.updateOthers(Id, formData).then((res) => {
  //         if (res?.status == "success") {
  //           swal(res?.message, "", "success");
  //           navigate("/setting");
  //         }
  //       });
  //     } else if (!Id) {
  //       api.addOthers(formData).then((res) => {
  //         if (res?.status == "success") {
  //           swal(res?.message, "", "success");
  //           navigate("/setting");
  //         }
  //       });
  //     }
  //   } else if (num == 3) {
  //     console.log("3", num);

  //     formData.append("min_km_time", input?.min_km_time);
  //     formData.append("amount", input?.amount);
  //     formData.append("type", 2);

  //     if (Id) {
  //       formData.append("_method", "PUT");
  //       api.addOthers(Id, formData).then((res) => {
  //         if (res?.status == "success") {
  //           swal(res?.message, "", "success");
  //           navigate("/setting");
  //         }
  //       });
  //     } else if (!Id) {
  //       api.updateOthers(formData).then((res) => {
  //         if (res?.status == "success") {
  //           swal(res?.message, "", "success");
  //           navigate("/setting");
  //         }
  //       });
  //     }
  //   } else if (num == 4) {
  //     console.log("4", num);

  //     if (Id) {
  //       formData.append("_method", "PUT");
  //       api.addPeak(Id, formData).then((res) => {
  //         if (res?.status == "success") {
  //           swal(res?.message, "", "success");
  //           navigate("/setting");
  //         }
  //       });
  //     } else if (!Id) {
  //       api.addPeak(formData).then((res) => {
  //         if (res?.status == "success") {
  //           swal(res?.message, "", "success");
  //           navigate("/setting");
  //         }
  //       });
  //     }
  //   } else if (num == 5) {
  //     console.log("5", num);

  //     if (Id) {
  //       formData.append("_method", "PUT");
  //       api.addPeak(Id, formData).then((res) => {
  //         if (res?.status == "success") {
  //           swal(res?.message, "", "success");
  //           navigate("/setting");
  //         }
  //       });
  //     } else if (!Id) {
  //       api.addPeak(formData).then((res) => {
  //         if (res?.status == "success") {
  //           swal(res?.message, "", "success");
  //           navigate("/setting");
  //         }
  //       });
  //     }
  //   }
  // };

  console.log("ride_id", ride_id);

  // RTK QUERY
  const [fareAddApi] = useFareAddMutation();
  const [fareEditApi] = useFareEditMutation();
  const [fareViewApi] = useLazyFareViewQuery();
  const [otherAddApi] = useOtherChargeAddMutation();
  const [otherEditApi] = useOtherChargeEditMutation();
  const [otherViewApi] = useLazyOtherChargeViewQuery();

  // BASE FARE ADD & EDIT
  const baseAdd = () => {
    let bases = {
      from: base?.from,
      amount: base?.amount,
      ride_type_id: ride_id,
    };

    if (base?.to) {
      bases.to = base?.to;
    }

    if (Id) {
      bases._method = "PUT";
      setBtn(true);
      fareEditApi({ id: Id, payload: bases })
        .then((res) => {
          setBtn(false);
          toast.success(res?.message, {
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            autoClose: 2000,
          });
          navigate(-1);
        })
        .catch((err) => {
          setBtn(false);
          console.log("err", err);
        });
    } else {
      setBtn(true);
      fareAddApi(bases)
        .unwrap()
        .then((res) => {
          setBtn(false);
          toast.success(res?.message, {
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            autoClose: 2000,
          });
          navigate(-1);
        })
        .catch((err) => {
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  // DISTANCE ADD & EDIT

  const distanceAdd = () => {
    let distanceFare = {
      min_km_time: distance?.min_km_time,
      amount: distance?.amount,
      ride_type_id: ride_id,
      type: 1,
    };

    if (Id) {
      setBtn(true);
      distanceFare._method = "PUT";
      otherEditApi({ id: Id, payload: distanceFare })
        .then((res) => {
          setBtn(false);
          toast.success(res?.message, {
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            autoClose: 2000,
          });
          navigate(-1);
        })
        .catch((err) => {
          setBtn(false);
          console.log("err", err);
        });
    } else {
      setBtn(true);
      otherAddApi(distanceFare)
        .unwrap()
        .then((res) => {
          setBtn(false);
          toast.success(res?.message, {
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            autoClose: 2000,
          });
          navigate(-1);
        })
        .catch((err) => {
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  // WAITING FARE ADD & EDIT

  const waitAdd = () => {
    let waitingFare = {
      min_km_time: wait?.min_km_time,
      amount: wait?.amount,
      ride_type_id: ride_id,
      type: 2,
    };

    if (Id) {
      setBtn(true);
      waitingFare._method = "PUT";
      otherEditApi({ id: Id, payload: waitingFare })
        .then((res) => {
          setBtn(false);
          toast.success(res?.message, {
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            autoClose: 2000,
          });
          navigate(-1);
        })
        .catch((err) => {
          setBtn(false);
          console.log("err", err);
        });
    } else {
      setBtn(true);
      otherAddApi(waitingFare)
        .unwrap()
        .then((res) => {
          setBtn(false);
          toast.success(res?.message, {
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            autoClose: 2000,
          });
          navigate(-1);
        })
        .catch((err) => {
          setBtn(false);
          console.log("err", err);
        });
    }
  };

  // PEAK ADD & EDIT

  const peakAdd = () => {
    let peakFare = {
      from_time: peak?.from_time,
      to_time: peak?.to_time,
      percentage: peak?.percentage,
      ride_type_id: ride_id,
      type: 1,
    };
    if (Id) {
      peakFare._method = "PUT";
      setBtn(true);
    } else if (!Id) {
      setBtn(true);
    }
  };

  // NIGHT PEAK ADD & EDIT

  const nightAdd = () => {
    let nightFare = {
      from_time: night?.from_time,
      to_time: night?.to_time,
      percentage: night?.percentage,
      ride_type_id: ride_id,
      type: 2,
    };

    if (Id) {
      nightFare._method = "PUT";
      setBtn(true);
    } else if (!Id) {
      setBtn(true);
    }
  };

  //  VIEW
  const viewList = () => {
    console.log("aaaa", "type", type);
    if (type == "editBaseFare") {
      let baseFare = {};
      fareViewApi(Id)
        .unwrap()
        .then((res) => {
          baseFare.from = res?.price?.from;
          baseFare.to = res?.price?.to;
          baseFare.amount = res?.price?.amount;
          setBase(baseFare);
          setBtnTo(res?.price?.to);
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else if (type == "editDistanceFare") {
      let distanceFare = {};
      otherViewApi(Id)
        .unwrap()
        .then((res) => {
          if (res?.other_charge?.type == 1) {
            distanceFare.min_km_time = res?.other_charge?.min_km_time;
            distanceFare.amount = res?.other_charge?.amount;
            setDistance(distanceFare);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else if (type == "editWaitingFare") {
      let waitingFare = {};
      otherViewApi(Id)
        .unwrap()
        .then((res) => {
          if (res?.other_charge?.type == 2) {
            waitingFare.min_km_time = res?.other_charge?.min_km_time;
            waitingFare.amount = res?.other_charge?.amount;
            setWait(waitingFare);
          }
        })
        .catch((err) => {
          console.log("err", err);
        });
    } else if (type == "editPeak") {
      let peakFaredata = {};
      let nightFaredata = {};
    }
  };

  console.log("Id", Id);

  useEffect(() => {
    if (Id) {
      viewList();
    }
  }, []);

  return (
    <>
      <aside>
        {type === "edit" ? (
          <TopHeader type="editFare" />
        ) : (
          <TopHeader type="addFare" />
        )}

        <article>
          {type == "addBaseFare" || type == "editBaseFare" ? (
            <>
              <div className="p-3">
                <div className="p-3 bg-white rounded-2">
                  <div className="dist-fare d-flex justify-content-between">
                    <label className="permission-text txt-primary4 text-uppercase">
                      Distance Fare
                    </label>
                    <span className="">
                      Note: You Should Give One Higher From Km without To Km
                    </span>
                  </div>
                  <Form className="d-flex flex-wrap">
                    {/* <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Fare Plan Name
                          </label>
                          <input
                            id="example-if-email"
                            name="title"
                            placeholder="fare plan name"
                            className="bg-transparent"
                            type="text"
                          />
                        </div>
                      </div>
                    </div> */}

                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            From
                          </label>
                          <input
                            id="example-if-email"
                            name="from"
                            placeholder="From"
                            className="bg-transparent"
                            onChange={handleChangeBase}
                            value={base?.from}
                            type="text"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            To
                          </label>
                          <input
                            placeholder="To"
                            name="to"
                            className="bg-transparent"
                            type="text"
                            onChange={handleChangeBase}
                            value={base?.to}
                            disabled={btnTo == null ? true : false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Price
                          </label>
                          <input
                            placeholder="price"
                            name="amount"
                            className="bg-transparent"
                            type="text"
                            onChange={handleChangeBase}
                            value={base?.amount}
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                  <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
                    <button
                      className="comn-btn bg-primary px-3 py-2 me-2"
                      onClick={baseAdd}
                      disabled={btn ? true : false}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {type == "addDistanceFare" || type == "editDistanceFare" ? (
            <>
              <div className="p-3">
                <div className="p-3 bg-white rounded-2">
                  <div className="flex-ac-jb">
                    <p
                      className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                      for=""
                    >
                      Base Fare
                    </p>
                  </div>

                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            KM
                          </label>
                          <input
                            id="example-if-email"
                            name="min_km_time"
                            placeholder="km"
                            className="bg-transparent"
                            type="text"
                            onChange={handleChangeDistance}
                            value={distance?.min_km_time}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Price
                          </label>
                          <input
                            placeholder="amount"
                            name="amount"
                            className="bg-transparent"
                            type="text"
                            onChange={handleChangeDistance}
                            value={distance?.amount}
                          />
                        </div>
                      </div>
                    </div>
                  </Form>
                  <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
                    <button
                      className="comn-btn bg-primary px-3 py-2 me-2"
                      onClick={distanceAdd}
                      disabled={btn ? true : false}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {type == "addWaitingFare" || type == "editWaitingFare" ? (
            <>
              <div className="p-3">
                <div className="p-3 bg-white rounded-2">
                  <div className="flex-ac-jb">
                    <p
                      className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                      for=""
                    >
                      Waiting Price
                    </p>
                  </div>
                  <Form className="d-flex flex-wrap">
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Minutes
                          </label>
                          <input
                            type="text"
                            id="example-if-email"
                            placeholder="Minutes"
                            name="min_km_time"
                            className="bg-transparent"
                            onChange={handleChangeWaiting}
                            value={wait?.min_km_time}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                      <div className="form-check ps-0">
                        <div className="px-1 col-sm-12 modal-label-input">
                          <label className="text-primary-dark" for="">
                            Waiting Price
                          </label>
                          <input
                            type="text"
                            id="example-if-email"
                            placeholder="Waiting Price"
                            name="amount"
                            className="bg-transparent"
                            onChange={handleChangeWaiting}
                            value={wait?.amount}
                          />
                        </div>
                      </div>
                    </div>
                  </Form>

                  <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
                    <button
                      className="comn-btn bg-primary px-3 py-2 me-2"
                      onClick={waitAdd}
                      disabled={btn ? true : false}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {/* {type == "addPeakFare" || type == "editPeak" ? (
            <>
              {(lg_fairtype === "Peak" || lg_fairtype == "All") && (
                <div className="p-3">
                  <div className="p-3 bg-white rounded-2">
                    <label>Peak Fare test</label>
                    <Form className="d-flex flex-wrap">
                      <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="text-primary-dark" for="">
                              Start Time
                            </label>
                            <input
                              type="time"
                              id="example-if-email"
                              placeholder="start time"
                              name="from_time"
                              className="bg-transparent"
                              onChange={handleChangePeak}
                              value={peak?.from_time}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="text-primary-dark" for="">
                              End Time
                            </label>
                            <input
                              type="time"
                              id="example-if-email"
                              placeholder="end time"
                              name="to_time"
                              onChange={handleChangePeak}
                              value={peak?.to_time}
                              className="bg-transparent"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="text-primary-dark" for="">
                              Peak Fare
                            </label>
                            <input
                              type="text"
                              id="example-if-email"
                              placeholder="peak fare"
                              name="percentage"
                              onChange={handleChangePeak}
                              value={peak?.percentage}
                              className="bg-transparent"
                            />
                          </div>
                        </div>
                      </div>
                    </Form>
                    <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
                      <button
                        className="comn-btn bg-primary px-3 py-2 me-2"
                        onClick={peakAdd}
                        disabled={btn ? true : false}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {(lg_fairtype === "Night" || lg_fairtype === "All") && (
                <div className="p-3">
                  <div className="p-3 bg-white rounded-2">
                    <label>Night Fare</label>
                    <Form className="d-flex flex-wrap">
                      <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="text-primary-dark" for="">
                              Start Time
                            </label>
                            <input
                              type="time"
                              id="example-if-email"
                              placeholder="start time"
                              name="from_time"
                              onChange={handleChangeNight}
                              value={night?.from_time}
                              className="bg-transparent"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="text-primary-dark" for="">
                              End Time
                            </label>
                            <input
                              type="time"
                              id="example-if-email"
                              placeholder="end time"
                              name="to_time"
                              onChange={handleChangeNight}
                              value={night?.to_time}
                              className="bg-transparent"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                        <div className="form-check ps-0">
                          <div className="px-1 col-sm-12 modal-label-input">
                            <label className="text-primary-dark" for="">
                              Night Fare
                            </label>
                            <input
                              type="text"
                              id="example-if-email"
                              placeholder="night fare"
                              name="percentage"
                              onChange={handleChangeNight}
                              value={night?.percentage}
                              className="bg-transparent"
                            />
                          </div>
                        </div>
                      </div>
                    </Form>
                    <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
                      <button
                        className="comn-btn bg-primary px-3 py-2 me-2"
                        onClick={nightAdd}
                        disabled={btn ? true : false}
                      >
                        Submit
                      </button>
                    </div>
                    <br></br>
                  </div>
                </div>
              )}
            </>
          ) : null} */}
        </article>
      </aside>
    </>
  );
};

export default AddFareSetting;
