import React from "react";
import { Form } from "react-bootstrap";
import { useState } from "react";
import TopHeader from "../../../component/TopHeader";
import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import { CiSquareRemove } from "react-icons/ci";
import { IoIosAdd } from "react-icons/io";
import {
  useLazyRewardViewQuery,
  useRewardAddMutation,
  useRewardEditMutation,
} from "../../../constants/redux/api/api";

const Reward = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const Id = location?.state?.id;
  const type = location?.state?.type;
  const [btn, setBtn] = useState(false);
  const [types, setTypes] = useState("");
  const [reward_percentage, setReward_percentage] = useState("");
  const [count, setCount] = useState("");
  const [no_of_days, setNo_of_days] = useState("");
  const [typesErr, setTypesErr] = useState(false);
  const [reward_percentageErr, setReward_percentageErr] = useState(false);
  const [countErr, setCountErr] = useState(false);
  const [no_of_daysErr, setNo_of_daysErr] = useState(false);
  const [max_amount,setMax_amount]=useState("");
  const [max_amountErr, setMax_amountErr] = useState(false);

  //RTK
  const [rewardViewApi] = useLazyRewardViewQuery();
  const [rewardAddApi] = useRewardAddMutation();
  const [rewardEditApi] = useRewardEditMutation();

  const submitHandler = () => {
    if (
      types?.length == 0 ||
      reward_percentage?.length == 0 ||
      count?.length == 0 ||
      no_of_days?.length == 0 ||
      max_amount?.length== 0
    ) {
      setTypesErr(true);
      setReward_percentageErr(true);
      setCountErr(true);
      setNo_of_daysErr(true);
      setMax_amount(true);
    } else {
      let formdata = new FormData();
      formdata.append("type", types);
      formdata.append("reward_percentage", reward_percentage);
      formdata.append("count", count);
      formdata.append("no_of_days", no_of_days);
      formdata.append("max_amount",max_amount);
      if (Id) {
        rewardEditApi({ id: Id, payload: formdata })
          .unwrap()
          .then((res) => {
            setBtn(false);
            if (res?.status == "success") {
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
              navigate("/rewards");
            } else {
              toast.error(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
            }
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      } else {
        rewardAddApi(formdata)
          .unwrap()
          .then((res) => {
            setBtn(false);
            if (res?.status == "success") {
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
              navigate("/rewards");
            } else {
              toast.error(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
            }
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      }
    }
  };

  const viewReward = () => {
    rewardViewApi(Id)
      .unwrap()
      .then((res) => {
        let data = res?.reward;
        setTypes(data?.type);
        setNo_of_days(data?.no_of_days);
        setReward_percentage(data?.reward_percentage);
        setMax_amount(data?.max_amount);
        setCount(data?.count);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (Id) {
      viewReward();
    }
  }, []);

  return (
    <aside>
      {type == "edit" ? (
        <TopHeader type="editReward" />
      ) : (
        <TopHeader type="addReward" />
      )}
      <article className="p-3">
        <div className="p-3">
          <div className="p-3 bg-white rounded-2">
            <Form className="d-flex flex-wrap">
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Type
                    </label>
                    <input
                      type="Name"
                      id="example-if-email"
                      name="name"
                      onChange={(e) => setTypes(e.target.value)}
                      placeholder="Type"
                      value={types}
                    />
                    {types?.length == 0 && typesErr && (
                      <p className="err-p">Enter Type</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Reward Percentage
                    </label>
                    <input
                      type="number"
                      id="example-if-email"
                      name="name"
                      onChange={(e) => setReward_percentage(e.target.value)}
                      placeholder="Reward Percentage"
                      value={reward_percentage}
                    />
                    {reward_percentage?.length == 0 && reward_percentageErr && (
                      <p className="err-p">Enter Reward Percentage</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                     Max Amount
                    </label>
                    <input
                      type="number"
                      id="example-if-email"
                      name="name"
                      onChange={(e) => setMax_amount(e.target.value)}
                      placeholder="Max amount"
                      value={max_amount}
                    />
                    {max_amount?.length == 0 && max_amountErr && (
                      <p className="err-p">Enter Max amount</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Count
                    </label>
                    <input
                      type="number"
                      id="example-if-email"
                      name="name"
                      onChange={(e) => setCount(e.target.value)}
                      placeholder="Count"
                      value={count}
                    />
                    {count?.length == 0 && countErr && (
                      <p className="err-p">Enter Count</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      No of Days
                    </label>
                    <input
                      type="number"
                      id="example-if-email"
                      name="name"
                      onChange={(e) => setNo_of_days(e.target.value)}
                      placeholder="No of Days"
                      value={no_of_days}
                    />
                    {no_of_days?.length == 0 && no_of_daysErr && (
                      <p className="err-p">Enter No of Days</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="mt-4 ms-auto">
                <button
                  type="button"
                  className={`comn-btn bg-success-gradient px-3 py-2 me-2 ${
                    btn ? "opacity-50" : ""
                  }`}
                  onClick={submitHandler}
                  disabled={btn ? true : false}
                >
                  Submit
                </button>
              </div>
            </Form>
          </div>
        </div>
      </article>
    </aside>
  );
};

export default Reward;
