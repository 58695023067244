import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  adminIcon,
  AppointmentIcon,
  BannerIcon,
  categoryIcon,
  dashbaordIcon,
  doctorIcon,
  FaqIcon,
  HelpIcon,
  hospitalIcon,
  Logout,
  NotifIcon,
  patientIcon,
  PrivacyIcon,
  RequestIcon,
  roleIcon,
  TermsCondiIcon,
} from "../assets/img";
import { ToastContainer, toast } from "react-toastify";
import { RxDashboard } from "react-icons/rx";
import { GiNotebook } from "react-icons/gi";
import {
  MdOutlineSubscriptions,
  MdOutlinePayment,
  MdOutlineSettings,
  MdOutlineNotificationAdd,
  MdOutlineStarRate,
  MdOutlineSupportAgent,
} from "react-icons/md";
import { AiOutlineSchedule } from "react-icons/ai";
import { CgList } from "react-icons/cg";
import { VscReferences } from "react-icons/vsc";
import {
  RiAdminLine,
  RiUserSettingsLine,
  RiMoneyCnyCircleLine,
  RiLogoutCircleRLine,
} from "react-icons/ri";
import {
  TbUserPlus,
  TbUserCheck,
  TbUsers,
  TbFileTime,
  TbReport,
} from "react-icons/tb";
import { useLazyLogoutQuery } from "../constants/redux/api/api";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log("loca", location);
  const { pathname } = location;
  const splitLocation = pathname.split("/");

  const permissons = JSON.parse(localStorage.getItem("role"));

  const [logoutApi] = useLazyLogoutQuery();

  // LOGOUT

  const Log = () => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Logout!",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.clear();
        window.location.reload();
        logoutApi()
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              localStorage.clear();
              window.location.reload();
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    });
  };

  return (
    <nav>
      <ul>
        <li>
          <Link
            type=""
            className={
              splitLocation[1] === "" ? "menu-list active" : "menu-list"
            }
            to="/"
          >
            {/* <img className="menu-icon" src={dashbaordIcon} /> */}
            <RxDashboard />
            Dashboard
          </Link>
        </li>

        {/* {permissons?.includes("CAN-LIST-ROLES") ? ( */}
        <li>
          <Link
            className={
              splitLocation[1] === "role" ? "menu-list active" : "menu-list"
            }
            to="/role"
          >
            {/* <img className="menu-icon" src={roleIcon} /> */}
            <RiUserSettingsLine />
            Roles
          </Link>
        </li>

        {/* {permissons?.includes("CAN-LIST-ADMINS") ? ( */}
        <li>
          <Link
            className={
              splitLocation[1] === "admin" ? "menu-list active" : "menu-list"
            }
            to="/admin"
          >
            {/* <img className="menu-icon" src={adminIcon} /> */}
            <RiAdminLine />
            Admins
          </Link>
        </li>
        {/* ) : null} */}

        <li>
          <Link
            className={
              splitLocation[1] === "user" ? "menu-list active" : "menu-list"
            }
            to="/user"
          >
            {/* <img className="menu-icon" src={roleIcon} /> */}
            <RiUserSettingsLine />
            User Details
          </Link>
        </li>
        <li>
          <Link
            className={
              splitLocation[1] === "carType" ? "menu-list active" : "menu-list"
            }
            to="/carType"
          >
            {/* <img className="menu-icon" src={roleIcon} /> */}
            <RiUserSettingsLine />
            Car Type
          </Link>
        </li>
        {/* ) : null} */}
        {/* {permissons?.includes("CAN-LIST-DRIVERS") ? ( */}
        <li>
          <Link
            className={
              splitLocation[1] === "driver" ? "menu-list active" : "menu-list"
            }
            to="/driver"
          >
            {/* <img className="menu-icon" src={adminIcon} /> */}
            <TbUserPlus />
            Driver Details
          </Link>
        </li>
        {/* ) : null} */}
        {/* {permissons?.includes("CAN-LIST-DRIVERS") ? ( */}
        <li>
          <Link
            className={
              splitLocation[1] === "driver-request"
                ? "menu-list active"
                : "menu-list"
            }
            to="/driver-request"
          >
            {/* <img className="menu-icon" src={adminIcon} /> */}
            <TbUserCheck />
            Driver KYC Request
          </Link>
        </li>
        {/* ) : null} */}
        {/* {permissons?.includes("CAN-LIST-USERS") ? ( */}
        <li>
          <Link
            className={
              splitLocation[1] === "booking" ? "menu-list active" : "menu-list"
            }
            to="/booking"
          >
            {/* <img className="menu-icon" src={adminIcon} /> */}
            <TbUsers />
            Booking History
          </Link>
        </li>
        {/* ) : null} */}
        {/* {permissons?.includes("CAN-LIST-BOOKING HISTORY") ? ( */}
        <li>
          <Link
            className={
              splitLocation[1] === "setting" ? "menu-list active" : "menu-list"
            }
            to="/setting"
          >
            {/* <img className="menu-icon" src={AppointmentIcon} /> */}
            <GiNotebook />
            Setting
          </Link>
        </li>
        {/* ) : null} */}
        {/* {permissons?.includes("CAN-LIST-REWARDS") ? ( */}
        {/* <li>
          <Link
            className={
              splitLocation[1] === "rewards" ? "menu-list active" : "menu-list"
            }
            to="/rewards"
          >
            {/* <img className="menu-icon" src={BannerIcon} /> 
            <RiMoneyCnyCircleLine />
            Rewards
          </Link>
        </li> */}
        {/* ) : null} */}
        {/* {permissons?.includes("CAN-LIST-RIDES") ? ( */}
        <li>
          <Link
            className={
              splitLocation[1] === "coupon" ? "menu-list active" : "menu-list"
            }
            to="/coupon"
          >
            {/* <img className="menu-icon" src={RequestIcon} /> */}
            <AiOutlineSchedule />
            Coupon
          </Link>
        </li>
        {/* ) : null} */}
        {/* {permissons?.includes("CAN-LIST-SUBSCRIPTIONS") ? ( */}
        <li>
          <Link
            className={
              splitLocation[1] === "subscription"
                ? "menu-list active"
                : splitLocation[1] === "subscription-add"
                ? "menu-list active"
                : "menu-list"
            }
            to="/subscription"
          >
            {/* <img className="menu-icon" src={NotifIcon} /> */}
            <MdOutlineSubscriptions />
            Subscription
          </Link>
        </li>
        <li>
          <Link
            className={
              splitLocation[1] === "rewards" ? "menu-list active" : "menu-list"
            }
            to="/rewards"
          >
            {/* <img className="menu-icon" src={RequestIcon} /> */}
            <AiOutlineSchedule />
            Rewards
          </Link>
        </li>
        <li>
          <Link
            className={
              splitLocation[1] === "faqs" ? "menu-list active" : "menu-list"
            }
            to="/faqs"
          >
            {/* <img className="menu-icon" src={RequestIcon} /> */}
            <AiOutlineSchedule />
            Faq
          </Link>
        </li>
        {/* ) : null} */}
        {/* {permissons?.includes("CAN-LIST-NOTIFICATION") ? ( */}
        <li>
          <Link
            className={
              splitLocation[1] === "push-notification"
                ? "menu-list active"
                : "menu-list"
            }
            to="/push-notification"
          >
            <MdOutlineNotificationAdd />
            Notification
          </Link>
        </li>
        {/* ) : null} */}
        {/* {permissons?.includes("CAN-LIST-PAY TAX") ? ( */}
        <li>
          <Link
            className={
              splitLocation[1] === "revenue-list"
                ? "menu-list active"
                : "menu-list"
            }
            to="/revenue-list"
          >
            {/* <img className="menu-icon" src={FaqIcon} /> */}
            <MdOutlinePayment />
            Pay Revenue
          </Link>
        </li>
        {/* ) : null} */}
        {/* {permissons?.includes("CAN-LIST-FARE PRICE") ? ( */}
        <li>
          <Link
            className={
              splitLocation[1] === "sos" ? "menu-list active" : "menu-list"
            }
            to="/sos"
          >
            {/* <img className="menu-icon" src={FaqIcon} /> */}
            <MdOutlineSettings />
            SOS
          </Link>
        </li>
        {/* ) : null} */}

        {/* {permissons?.includes("CAN-LIST-OFFLINE BOOOKING") ? ( */}
        {/* <li>
          <Link
            className={
              splitLocation[1] === "offline-booking"
                ? "menu-list active"
                : "menu-list"
            }
            to="/offline-booking"
          >
            {/* <img className="menu-icon" src={FaqIcon} /> 
            <GiNotebook />
            Offline Booking
          </Link>
        </li> */}
        {/* ) : null} */}
        {/* {permissons?.includes("CAN-LIST-ACTIVITY LOG") ? ( */}
        <li>
          <Link
            className={
              splitLocation[1] === "refer-earn"
                ? "menu-list active"
                : "menu-list"
            }
            to="/refer-earn"
          >
            {/* <img className="menu-icon" src={FaqIcon} /> */}
            <TbFileTime />
            Refer and Earn
          </Link>
        </li>
        {/* ) : null} */}
        {/* {permissons?.includes("CAN-LIST-REFER AND EARN") ? ( */}
        {/* <li>
          <Link
            className={
              splitLocation[1] === "refer-earn"
                ? "menu-list active"
                : "menu-list"
            }
            to="/refer-earn"
          >
            {/* <img className="menu-icon" src={FaqIcon} /> 
            <VscReferences />
            Refer and Earn
          </Link>
        </li> */}
        {/* ) : null} */}

        {/* {permissons?.includes("CAN-LIST-REVIEW AND RATING") ? ( */}
        {/* <li>
          <Link
            className={
              splitLocation[1] === "review-rating"
                ? "menu-list active"
                : "menu-list"
            }
            to="/review-rating"
          >
           
            <MdOutlineStarRate />
            Review & Rating
          </Link>
        </li> */}
        {/* ) : null} */}
        {/* {permissons?.includes("CAN-LIST-HELP AND SUPPORTS") ? ( */}
        <li>
          <Link
            className={
              splitLocation[1] === "help-support"
                ? "menu-list active"
                : "menu-list"
            }
            to="/help-support"
          >
            {/* <img className="menu-icon" src={HelpIcon} /> */}
            <MdOutlineSupportAgent />
            Help & Support
          </Link>
        </li>
        {/* ) : null} */}
        {/* {permissons?.includes("CAN-LIST-REPORTS") ? ( */}
        {/* <li>
          <Link
            className={
              splitLocation[1] === "report" ? "menu-list active" : "menu-list"
            }
            to="/report"
          >
           
            <TbReport />
            Report
          </Link>
        </li> */}
        {/* ) : null} */}
        <li>
          <Link
            className="menu-list"
            onClick={() => {
              Log();
            }}
          >
            {/* <img className="menu-icon" src={Logout} /> */}
            <RiLogoutCircleRLine />
            Logout
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
