import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faEye } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { bannerList, maleAvatar } from "../../../assets/img";
import Pagination from "react-bootstrap/Pagination";
import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import moment from "moment/moment";

const UserHistory = () => {
  const navigate = useNavigate();

  const location = useLocation();

  const Id = location?.state?.id;

  const name = location?.state?.name;

  const [list, setList] = useState();

  const getList = () => {};

  useEffect(() => {
    if (Id) {
      getList();
    }
  }, []);

  return (
    <aside>
      <TopHeader type="user-history" />
      <article className="p-3">
        <article>
          <div className="p-3">
            <div className="p-3 bg-white rounded-2">
              <div className="flex-ac-jb">
                <p
                  className="permission-text txt-primary4 mb-0 mt-0 text-uppercase"
                  for=""
                >
                  {name}
                </p>
                <img
                  className="avatar-img"
                  src={list?.driver?.img_url || maleAvatar}
                />
              </div>
              <Form className="d-flex flex-wrap">
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Driver Name
                      </label>
                      <input
                        id="example-if-email"
                        name="Phone_Number"
                        placeholder="Driver Name"
                        className="bg-transparent"
                        type="text"
                        value={list?.driver?.name}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        From
                      </label>
                      <input
                        type="email"
                        id="example-if-email"
                        placeholder="From"
                        className="bg-transparent"
                        value={list?.ride_detail?.pickup_address}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        To
                      </label>
                      <input
                        placeholder="to"
                        type="text"
                        name="aadhaar number"
                        className="bg-transparent"
                        value={list?.ride_detail?.drop_address}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Date
                      </label>
                      <input
                        type="text"
                        name="license-number"
                        id="example-if-email"
                        placeholder="license number"
                        className="bg-transparent"
                        value={
                          list?.updated_at
                            ? moment(list?.updated_at).format("DD/MM/YYYY")
                            : ""
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Ride Cost
                      </label>
                      <input
                        placeholder="Cost"
                        className="bg-transparent"
                        type="text"
                        value={list?.final_amount}
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Payment Method
                      </label>
                      <input
                        type="text"
                        id="example-if-email"
                        placeholder="Payment"
                        className="bg-transparent"
                        value={
                          list?.payment_type == 1
                            ? "Manual Pay"
                            : list?.payment_type == 2
                            ? "UPI"
                            : ""
                        }
                        disabled
                      />
                    </div>
                  </div>
                </div>

                {/* <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        User Review
                      </label>
                      <input
                        type="text"
                        name="city"
                        className="bg-transparent"
                        placeholder="city"
                        value={"*****"}
                      />
                    </div>
                  </div>
                </div> */}

                <div className="form-group col-12 col-sm-6 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Driver Review
                      </label>
                      {/* <input
                        placeholder={
                          list?.ride_review?.driver_rating == 1
                            ? "&#9733;"
                            : list?.ride_review?.driver_rating == 2
                            ? "&#9733;&#9733;"
                            : list?.ride_review?.driver_rating == 3
                            ? "&#9733;&#9733;&#9733;"
                            : list?.ride_review?.driver_rating == 4
                            ? "&#9733;&#9733;&#9733;&#9733;"
                            : list?.ride_review?.driver_rating == 5
                            ? "&#9733;&#9733;&#9733;&#9733;&#9733;"
                            : null
                        }
                        type="text"
                        name="address"
                        className="bg-transparent"
                        disabled
                      /> */}
                      {list?.ride_review?.driver_rating == 1 ? (
                        <p style={{ color: "#FBC740" }}>&#9733;</p>
                      ) : list?.ride_review?.driver_rating == 2 ? (
                        <p style={{ color: "#FBC740" }}>&#9733;&#9733;</p>
                      ) : list?.ride_review?.driver_rating == 3 ? (
                        <p style={{ color: "#FBC740" }}>
                          &#9733; &#9733; &#9733;
                        </p>
                      ) : list?.ride_review?.driver_rating == 4 ? (
                        <p style={{ color: "#FBC740" }}>
                          &#9733; &#9733; &#9733; &#9733;
                        </p>
                      ) : list?.ride_review?.driver_rating == 5 ? (
                        <p style={{ color: "#FBC740" }}>
                          &#9733; &#9733; &#9733; &#9733; &#9733;
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="form-group col-12 ps-0">
                  <div className="form-check ps-0">
                    <div className="px-1 col-sm-12 modal-label-input">
                      <label className="text-primary-dark" for="">
                        Feedback
                      </label>
                      <p className="address-request textarea-height" disabled>
                        {list?.ride_review?.driver_review}
                      </p>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </article>
      </article>
    </aside>
  );
};

export default UserHistory;
