import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import swal from "sweetalert";
import { useLocation, useNavigate } from "react-router-dom";
import TopHeader from "../../../component/TopHeader";
import { hidePwdImg, showPwdImg } from "../../../assets/img";
import { ToastContainer, toast } from "react-toastify";
import { useAddZoneMutation, useLazyEditZoneQuery, useUpdateZoneMutation } from "../../../constants/redux/api/api";

const AddZone = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const Id = location?.state?.id;
  const type = location?.state?.type;

  const [input, setInput] = useState([]);
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [pin, setPin] = useState("");
  const [btn, setBtn] = useState(false);

  const [areaErr, setAreaErr] = useState(false);
  const [cityErr, setCityErr] = useState(false);
  const [pinErr, setPinErr] = useState(false);
  //RTK
  const[addZoneApi]=useAddZoneMutation();
  const[editZoneApi]=useLazyEditZoneQuery();
  const [updateZoneApi]=useUpdateZoneMutation()

  const addZone = () => {
    if (area?.length == 0 || city?.length == 0 || pin?.length == 0) {
      setAreaErr(true);
      setCityErr(true);
      setPinErr(true);
    } else {
      if (Id) {
        let formData = new FormData();
        formData.append("city", city);
        formData.append("zone", area);
        formData.append("pin_code", pin);
        formData.append("_method", "PUT");
        setBtn(true);
        updateZoneApi({id:Id,payload:formData}).then((res) => {
          // if (res?.status == "success") {
            navigate("/setting");
            toast.success(res?.message, {
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              autoClose: 2000,
            });
            setBtn(false);
          // } else {
          //   setBtn(false);
          // }
        }).catch(err=>{
          setBtn(false);
        })
      } else if (!Id) {
        let formData = new FormData();
        formData.append("city", city);
        formData.append("zone", area);
        formData.append("pin_code", pin);
        setBtn(true);
        addZoneApi(formData).then((res) => {
          // if (res?.status == "success") {
            setBtn(false);
            toast.success(res?.message, {
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              autoClose: 2000,
            });
            navigate("/setting");
          // } else {
          //   setBtn(false);
          // }
        }).catch(err=>{
          setBtn(false);
        })
      }
    }
  };


  const view = () => {
    editZoneApi(Id).unwrap().then((res) => {
      if (res?.status == "success") {
        setCity(res?.zone?.city);
        setArea(res?.zone?.zone);
        setPin(res?.zone?.pin_code);
      }
    });
  };


  useEffect(() => {
    if (Id) {
      view();
    }
  }, []);

  return (
    <aside>
      {type == "edit" ? (
        <TopHeader type="edit-zone" />
      ) : (
        <TopHeader type="add-zone" />
      )}
      <article className="p-3">
        <div className="p-3">
          <div className="p-3 bg-white rounded-2">
            <Form className="d-flex flex-wrap">
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Area
                    </label>
                    <input
                      type="Name"
                      id="example-if-email"
                      name="name"
                      onChange={(e) => setArea(e.target.value)}
                      placeholder="area"
                      value={area}
                    />
                    {area?.length == 0 && areaErr && (
                      <p className="err-p">enter area</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      City
                    </label>
                    <input
                      type="Name"
                      id="example-if-email"
                      name="username"
                      onChange={(e) => setCity(e.target.value)}
                      placeholder="city"
                      value={city}
                    />
                    {city?.length == 0 && cityErr && (
                      <p className="err-p">enter city</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Pincode
                    </label>
                    <input
                      type="number"
                      id="example-if-email"
                      name="number"
                      onChange={(e) => setPin(e.target.value)}
                      placeholder="pincode"
                      value={pin}
                    />
                    {pin?.length == 0 && pinErr && (
                      <p className="err-p">enter pincode</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="mt-auto ms-auto">
                <button
                  type="button"
                  className="comn-btn bg-success-gradient px-3 py-2 me-2"
                  onClick={addZone}
                  disabled={btn ? true : false}
                >
                  Submit
                </button>
              </div>
            </Form>
          </div>
        </div>
      </article>
    </aside>
  );
};

export default AddZone;
