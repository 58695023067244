import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Tab, Tabs } from "react-bootstrap";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import Booking from "./Booking";
import BookingCompleted from "./BookingCompleted";
import BookingSchedule from "./BookingSchedule";
import BookingRejected from "./BookingRejected";

const BookingList = () => {
  return (
    <aside>
      <TopHeader type="booking" />
      <article className="p-3">
        <Tabs
          defaultActiveKey="ongoing"
          id="uncontrolled-tab-example"
          className="tab-appoinment w-50 m-3"
          fill
        >
          <Tab eventKey="ongoing" title="Ongoing List">
            <Booking />
          </Tab>
          <Tab eventKey="resolved" title="Completed List">
            <BookingCompleted />
          </Tab>
          <Tab eventKey="reject" title="Rejected List">
            <BookingRejected />
          </Tab>
          <Tab eventKey="schedule" title="Schedule List">
            <BookingSchedule />
          </Tab>
        </Tabs>
      </article>
    </aside>
  );
};

export default BookingList;
