import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Tab, Tabs } from "react-bootstrap";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import ReferEarn from "./referEarn";
import UserList from "./user";

const PayRevenue = () => {
  const [data, setData] = useState();
  return (
    <aside>
      {/* <TopHeader type="referEarn" /> */}
      <article className="p-3">
        <Tabs
          defaultActiveKey="Detail-View"
          id="uncontrolled-tab-example"
          className="mb-3 tab-appoinment"
        >
          <Tab eventKey="Detail-View" title="Driver List">
            <div className="p-3">
              <ReferEarn />
            </div>
          </Tab>
          <Tab eventKey="Detail" title="User List">
            <div className="p-3">
              <UserList />
            </div>
          </Tab>
          
        </Tabs>
      </article>

    </aside>
  );
};

export default PayRevenue;
