import React, { useEffect, useState } from "react";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Form } from "react-bootstrap";
// import Pagination from "react-bootstrap/Pagination";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import BTable from "react-bootstrap/Table";

import { GlobalFilter } from "../Roles/GlobalFilter";

import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";

import ReactPaginate from "react-paginate";
import swal from "sweetalert";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import moment from "moment";
import {
  useLazyCarViewQuery,
  useLazyPushNotificationListQuery,
  usePushNotifiDeleteMutation,
  usePushNotificationAddMutation,
  useLazyPushuserlistQuery,
  useLazyUserlistQuery,
  useLazyPushdriverlistQuery,
  useLazyPushNotificationDeleteQuery,
} from "../../../constants/redux/api/api";

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,

    globalFilter,
    setGlobalFilter,

    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <>
      {/* show */}
      <Row className="mb-3 table-show row">
        <Col className="d-flex align-items-center">
          <p>Show</p>

          <select
            className="form-control w-auto mx-2 p-2 py-1"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {[5, 10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <p>entries</p>
        </Col>
        <Col className="d-flex justify-content-end">
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
          {/* <Link to="/support/add-support">
            <Button style={{ background: 'rgb(95,188,95)' }} className="btn-sm btn-round has-ripple ml-2 border-0">
              <i className="feather icon-plus" /> Add Ticket
            </Button>
          </Link> */}
        </Col>
      </Row>
      {/* show */}
      <BTable size="sm" striped bordered hover responsive {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                // Add the sorting props to control sorting. For this example
                // we can add them into the header props
                <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                  {column.render("Header")}
                  {/* Add a sort direction indicator */}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <span className="feather icon-arrow-down text-muted float-right" />
                      ) : (
                        <span className="feather icon-arrow-up text-muted float-right" />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
      {/* pagination */}
      <Row className="justify-content-between table-show my-2">
        <Col>
          <span className="d-flex align-items-center">
            <p className="px-1">Page</p>{" "}
            <strong>
              {" "}
              {pageIndex + 1} of {pageOptions.length}{" "}
            </strong>{" "}
            <p className="px-1">|</p>
            <p className="px-1">Go to page:</p>{" "}
            <input
              type="number"
              className="form-control ml-2  p-1 py-1 text-center"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              style={{ width: "40px" }}
            />
          </span>
        </Col>
        <Col>
          <Pagination className="justify-content-end ">
            <Pagination.First
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            />
            <Pagination.Prev
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            />
            <Pagination.Next
              onClick={() => nextPage()}
              disabled={!canNextPage}
            />
            <Pagination.Last
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            />
          </Pagination>
        </Col>
      </Row>
      {/*paginations  */}
    </>
  );
}

const PushNotification = () => {
  const navigate = useNavigate();
  const permissons = JSON.parse(localStorage.getItem("role"));

  const [addModal, setAddModal] = useState(false);

  const [user_id, setUser_id] = useState("");
  const [driver_id, setDriver_id] = useState("");

  const [title,setTitle] =useState("");
  const [message,setMessage] =useState("");
  const [type,setType]=useState("");
  const [imagePre, setImagePre] = useState("");
  const [driverErr, setDriverErr] = useState(false);
  const [userErr, setUserErr] = useState(false);
  const [driver, setDriver] = useState("");
  const [userList, setUserList] = useState([]);

  const [driverList, setDriverList] = useState([]);
  const [user, setUser] = useState("");
  // RTK
  const [deleteApi] = usePushNotifiDeleteMutation();
  const [addNotifyApi] = usePushNotificationAddMutation();
  const [listApi] = useLazyPushNotificationListQuery();
  const [cartView]=useLazyCarViewQuery();
  const [userlist]=useLazyPushuserlistQuery();
  const [driverAcceptApi] = useLazyPushdriverlistQuery();
  const [deletesApi]=useLazyPushNotificationDeleteQuery();
  const columns = React.useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "sno",
        Cell: ({ value, row }) => {
          return <>{row.index + 1}</>;
        },
      },
      {
        Header: "Date",
        accessor: "created_at",
        Cell: ({ value, row }) => {
          return <>{moment(value).format("DD/MM/YYYY")}</>;
        },
      },

      {
        Header: "Tittle",
        accessor: "title",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      {
        Header: "Description",
        accessor: "description",
        Cell: ({ value, row }) => {
          return <>{value}</>;
        },
      },
      // {
      //   Header: "Image",
      //   accessor: "img_url",
      //   Cell: ({ value, row }) => {
      //     return <img src={value} style={{ width: "80px", height: "50px" }} />;
      //   },
      // },

      {
        Header: "Action",
        accessor: "action",
        Cell: ({ value, row }) => {
          return (
            <React.Fragment>
              <div className="d-flex gap-2">
                {/* <button
                  className="comn-btn bg-primary edit-btn-svg"
                  onClick={() =>
                    navigate("/push-notification", {
                      state: {
                        type: "edit",
                        id: row.original.id,
                      },
                    })
                  }
                  disabled={btn}
                >
                  <FontAwesomeIcon icon={faEye} />
                </button> */}
                <button
                  className="comn-btn bg-danger edit-btn-svg"
                  onClick={() => deleteFares(row.original.id)}
                  disabled={btn}
                >
                  <FontAwesomeIcon icon={faTrash} />
                </button>
              </div>
            </React.Fragment>
          );
        },
      },
    ],
    []
  );
  const [list, setList] = useState([]);
  const deleteFares = (id) => {
    Swal.fire({
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete!",
    }).then((result) => {
      if (result.isConfirmed) {
        setBtn(true);
        deletesApi(id)
          .unwrap()
          .then((res) => {
            setBtn(false);
            Swal.fire("Delete", "Delete Successfully.", "success");
            getList();
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      }
    });
  };
  const getList = () => {
    listApi()
      .unwrap()
      .then((res) => {
        setList([...res?.notifications].reverse());      });
  };
  const apiUser = () => {
    userlist()
      .unwrap()
      .then((res) => {
        console.log(res);
        setUserList(res?.active_users);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const apiDriver = () => {
    driverAcceptApi()
      .unwrap()
      .then((res) => {
        setDriverList(res?.drivers);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    apiUser();
    apiDriver();
    getList();
  }, []);

  const [input, setInput] = useState([]);
  const [btn, setBtn] = useState(false);

  const handleChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setInput((pre) => ({ ...pre, [name]: value }));
  };

  const addNotify = () => {
    // let data = {
    //   title: input?.title,
    //   description: input?.description,
    //   image: image,
    //   type: input?.type,
    // };
    let data=new FormData();
    data.append("title",title);
    data.append("message",message);
    if(user){
    data.append("user_id", user);    }
    if(driver){
      data.append("driver_id", driver);
    }
    data.append("type",type);
    setBtn(true);
    console.log(user,"data")
    addNotifyApi(data)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setBtn(false);
          // setImage("");
          // setImagePre("");
          toast.success(res?.message, {
            position: "top-right",
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            autoClose: 2000,
          });
          setAddModal(false);
          getList();
        } else {
          setBtn(false);
        }
      });
  };

  const deleteNotify = (id) => {
    Swal.fire({
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Delete!",
    }).then((result) => {
      setBtn(true);
      if (result.isConfirmed) {
        deleteApi(id)
          .then((res) => {
            // if (res.status == "success") {
            setBtn(false);
            toast.success(res?.message, {
              position: "top-right",
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              autoClose: 2000,
            });
            getList();
            
            // } else {
            //   setBtn(false);
            // }
          })
          .catch((err) => {
            setBtn(false);
          });
      }
    });
  };

  return (
    <aside>
      <TopHeader type="push-notification" />
      <article className="p-3">
        <div className="p-2 bg-white rounded-2">
          <div className="ad-btn w-100 flex-ac-jb p-2 d-flex justify-content-end">
            <div className="flex-ac-jc">
              {/* {permissons?.includes("CAN-ADD-NOTIFICATION") ? ( */}
              <button
                onClick={() => setAddModal(true)}
                className="comn-btn bg-primary1 px-3 py-2"
              >
                Add Notification
              </button>
              {/* ) : null} */}
            </div>
          </div>
          <Table columns={columns} data={list} />
        </div>
      </article>

      <Modal
        className="table-modal"
        show={addModal}
        onHide={() => setAddModal(false)}
        aria-labelledby="example-modal-sizes-title-lg"
      >
        <div className="px-2 py-2 col-12 d-flex flex-wrap">
          <div className="px-1 col-sm-12 modal-label-input">
            <h4>Add Notification</h4>
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Tittle
            </label>
            <input
              type="title"
              id="example-if-email"
              name="title"
              placeholder="title"
              onChange={(e)=>setTitle(e.target.value)}
              value={title}
            />
          </div>
          <div className="px-1 col-sm-6 modal-label-input">
      <label className="text-primary-dark" htmlFor="">
        Send To Type
      </label>
      <select name="type" onChange={(e) => setType(e.target.value)}>
        <option value="" disabled selected>Select type</option>
        <option value={1}>All users</option>
        <option value={2}>All Drivers</option>
        <option value={3}>Single User</option>
        <option value={4}>Single Driver</option>
      </select>

      {type == "3" && (
            <div className="px-1 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                Send to User
              </label>
              <select
                name="type"
                onChange={(e) => setUser(e.target.value)}
                value={user}
              >
                <option selected>select type</option>
                {userList?.map((item) => {
                  return <option value={item?.id}>{item?.name}</option>;
                })}
              </select>
              {user?.length == "" && userErr && (
                <p className="err-p">Enter User </p>
              )}
            </div>
          )}
          {type == "4" && (
            <div className="px-1 col-sm-12 modal-label-input">
              <label className="text-primary-dark" for="">
                Send To Driver
              </label>
              <select
                name="type"
                onChange={(e) => setDriver(e.target.value)}
                value={driver}
              >
                <option value={""}>select user</option>
                {driverList?.map((item, ind) => {
                  return <option value={item?.id}>{item?.name}</option>;
                })}
              </select>
              {driver?.length == "" && driverErr && (
                <p className="err-p">Enter Driver</p>
              )}
            </div>
          )}
          </div>
          {/* <div className="px-1 col-sm-6 modal-label-input">
              <label className="text-primary-dark" for="">
                Filtering Account (By Name, Email, Mobile number and ID)
              </label>
              <input
                type="title"
                id="example-if-email"
                name="title"
                placeholder=""
                onChange={handleChange}

              />
            </div> */}
          <div className="form-group col-12 col-sm-12 ps-0">
            <div className="form-check ps-0">
              <div className="px-1 col-sm-12 modal-label-input">
                <label className="text-primary-dark" for="">
                  Message
                </label>
                <textarea
                  placeholder="Description"
                  type="text"
                  className="bg-transparent"
                  cols="30"
                  rows="10"
                  name="message"
                  onChange={(e)=>setMessage(e.target.value)}
                  value={message}
                ></textarea>
              </div>
            </div>
          </div>
          {/* <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              User Id
            </label>
            <input
              type="text"
              id="example-if-email"
              name="user_id"
              placeholder="User Id"
              onChange={(e) =>setUser_id(e.target.value)
              }
              value={user_id}
            />
            
          </div> */}
          {/* <div className="px-1 col-sm-6 modal-label-input">
            <label className="text-primary-dark" for="">
              Driver Id
            </label>
            <input
              type="text"
              id="example-if-email"
              name="driver_id"
              placeholder="Driver Id"
              onChange={(e) =>setDriver_id(e.target.value)
              }
              value={driver_id}
            />
             */}
          {/* </div> */}
        </div>
        
        <div className="col-xs-12 d-flex justify-content-end pr-2 mb-2">
          <button
            onClick={() => {
              setAddModal(false);
            }}
            className="comn-btn bg-hash2 px-3 py-2 me-2"
          >
            Cancel
          </button>
          <button
            type="button"
            className="comn-btn bg-success px-3 py-2 me-2"
            data-dismiss="modal"
            onClick={addNotify}
            disabled={btn ? true : false}
          >
            Submit
          </button>
        </div>
      </Modal>
    </aside>
  );
};

export default PushNotification;
