import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Tab, Tabs } from "react-bootstrap";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import PendingHelp from "./PendingHelp";
import ResolveHelp from "./resolveHelp";

const SOS = () => {
  const [data, setData] = useState();
  return (
    <aside>
      <TopHeader type="sos" />
      <article className="p-3">
        <Tabs
          defaultActiveKey="pending"
          id="uncontrolled-tab-example"
          className="tab-appoinment"
          fill
        >
          <Tab eventKey="pending" title="Alert">
            <PendingHelp setData={setData} />
          </Tab>

          <Tab eventKey="resolved" title="Resolved">
            <ResolveHelp data={data} />
          </Tab>
        </Tabs>
      </article>
    </aside>
  );
};

export default SOS;
