import React, { useEffect, useState } from "react";
import {
  faBell,
  faBellSlash,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight, faHome } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import useUser from "../constants/Storage/userData";
import { useLazyLogoutQuery } from "../constants/redux/api/api";

const TopHeader = (props) => {
  const { user } = useUser();
  const navigate = useNavigate();

  const [status, setstatus] = useState("");

  const NotifyStatus = () => {};

  const notify = () => {};

  // useEffect(() => {
  //   NotifyStatus();
  // }, []);

  const [logoutApi] = useLazyLogoutQuery();

  const Log = () => {
    Swal.fire({
      title: "Are you sure?",
      // text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Logout!",
    }).then((result) => {
      if (result.isConfirmed) {
        logoutApi()
          .unwrap()
          .then((res) => {
            if (res?.status == "success") {
              localStorage.clear();
              window.location.reload();
            }
          })
          .catch((err) => {
            console.log("err", err);
          });
      }
    });
  };

  return (
    <div className="">
      <div className="flex-ac-jb header-rit-top px-4 py-2">
        <div className="">
          {props.type == "dashboard" && <h4>Dashboard</h4>}
          {props.type == "admin" && <h4>Admin</h4>}
          {props.type == "add-admin" && (
            <div className="">
              <h4>Add Admins</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to={"/Admin"}>
                    <FontAwesomeIcon icon={faHome} /> Admins
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Add Admin</li>
              </ul>
            </div>
          )}
          {props.type == "edit-admin" && (
            <div className="">
              <h4>Edit Admins</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to={"/Admin"}>
                    <FontAwesomeIcon icon={faHome} /> Admins
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Edit Admin</li>
              </ul>
            </div>
          )}
          {/* ROLES */}
          {props.type == "role" && <h4>Roles</h4>}
          {props.type == "add-role" && (
            <div className="">
              <h4>Add Roles</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to={"/role"}>
                    <FontAwesomeIcon icon={faHome} /> Roles
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Add Roles</li>
              </ul>
            </div>
          )}
          {props.type == "edit-role" && (
            <div className="">
              <h4>Edit Roles</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to={"/role"}>
                    <FontAwesomeIcon icon={faHome} /> Roles
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Edit Roles</li>
              </ul>
            </div>
          )}
          {props.type == "view-role" && (
            <div className="">
              <h4>View Roles</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to={"/role"}>
                    <FontAwesomeIcon icon={faHome} /> Roles
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>view Roles</li>
              </ul>
            </div>
          )}

          {/* vehicle-category */}

          {props.type == "vehicle-category" && <h4>Vehicle Category</h4>}
          {props.type == "add-vehicle" && (
            <div className="">
              <h4>Add Vehicle</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to={"/vehicle-category"}>
                    <FontAwesomeIcon icon={faHome} /> Vehicle Category
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Add Vehicle Category</li>
              </ul>
            </div>
          )}
          {props.type == "edit-vehicle" && (
            <div className="">
              <h4>Edit Vehicle</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to={"/vehicle-category"}>
                    <FontAwesomeIcon icon={faHome} /> Vehicle Category
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Edit Vehicle Category</li>
              </ul>
            </div>
          )}
          {props.type == "view-vehicle" && (
            <div className="">
              <h4>View Vehicle</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to={"/vehicle-category"}>
                    <FontAwesomeIcon icon={faHome} /> Vehicle Category
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>view Vehicle Category</li>
              </ul>
            </div>
          )}

          {/* DRIVER REQUEST */}
          {props.type == "driver-request" && <h4>Driver Request</h4>}
          {props.type == "driver-document" && (
            <div className="">
              <h4>Driver Details</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to="/driver-request">
                    <FontAwesomeIcon icon={faHome} /> Request
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Driver-Details</li>
              </ul>
            </div>
          )}
          {/* DRIVER */}
          {props.type == "driver" && <h4>Drivers</h4>}
          {props.type == "view-profile" && (
            <div className="">
              <h4>Driver Profile</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to={"/driver"}>
                    <FontAwesomeIcon icon={faHome} /> Driver
                  </Link>
                </li>
                <li className="me-1">
                  <Link to={"/driver/view-profile"}>
                    <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                    Driver-Profile
                  </Link>
                </li>
              </ul>
            </div>
          )}
          {props.type == "driver-history" && (
            <div>
              <h4>History</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to={"/driver"}>
                    <FontAwesomeIcon icon={faHome} /> Driver
                  </Link>
                </li>
                <li className="me-1">
                  <Link to={"/driver/view-profile"}>
                    <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                    Driver-Profile
                  </Link>
                </li>
                <li className="me-1">
                  <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                  History
                </li>
              </ul>
            </div>
          )}
          {/* USER */}
          {props.type == "user" && <h4>Users</h4>}
          {props.type == "user-profile" && (
            <div className="">
              <h4>User Profile</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to={"/user"}>
                    <FontAwesomeIcon icon={faHome} /> User
                  </Link>
                </li>
                <li className="me-1">
                  <Link to={"/user/view-profile"}>
                    <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                    User-Profile
                  </Link>
                </li>
              </ul>
            </div>
          )}
          {props.type == "user-history" && (
            <div>
              <h4>History</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to={"/user"}>
                    <FontAwesomeIcon icon={faHome} /> User
                  </Link>
                </li>
                <li className="me-1">
                  {/* <Link to={"/user/view-profile"}> */}
                  <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                  User-Profile
                  {/* </Link> */}
                </li>
                <li className="me-1">
                  <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                  History
                </li>
              </ul>
            </div>
          )}
          {/* BOOKING HISTORY */}
          {props.type == "booking" && <h4>Booking History</h4>}
          {props.type == "view-booking" && (
            <div className="">
              <h4>View Booking History</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to="/booking">
                    <FontAwesomeIcon icon={faHome} /> Booking-History
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>View-Booking-History</li>
              </ul>
            </div>
          )}
          {/* COUPON */}
          {props.type == "coupon" && <h4>Coupon</h4>}
          {props.type == "addCoupen" && (
            <div className="">
              <h4>Add Coupon</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to="/coupon">
                    <FontAwesomeIcon icon={faHome} /> Coupon
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Add-Coupon</li>
              </ul>
            </div>
          )}
          {props.type == "editCoupen" && (
            <div className="">
              <h4>Edit Coupon</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to="/coupon">
                    <FontAwesomeIcon icon={faHome} /> Coupon
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Edit-Coupon</li>
              </ul>
            </div>
          )}
          {/* REWARDS */}
          {props.type == "reward" && <h4>Rewards</h4>}
          {props.type == "addReward" && (
            <div className="">
              <h4>Add Reward</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to="/rewards">
                    <FontAwesomeIcon icon={faHome} /> Rewards
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Add-Reward</li>
              </ul>
            </div>
          )}
          {props.type == "editReward" && (
            <div className="">
              <h4>Edit Reward</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to="/rewards">
                    <FontAwesomeIcon icon={faHome} /> Rewards
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Edit-Reward</li>
              </ul>
            </div>
          )}
            {/* FAQ */}
            {props.type == "faq" && <h4>FAQ</h4>}
          {props.type == "addFaq" && (
            <div className="">
              <h4>Add FAQ</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to="/faqs">
                    <FontAwesomeIcon icon={faHome} /> FAQ
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Add-FAQ</li>
              </ul>
            </div>
          )}
          {props.type == "editFaq" && (
            <div className="">
              <h4>Edit Faq</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to="/faqsadd">
                    <FontAwesomeIcon icon={faHome} /> FAQ
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Edit-FAQ</li>
              </ul>
            </div>
          )}
          {/* SUBSCRIPTION */}
          {props.type == "subscription" && <h4>Subscription</h4>}
          {props.type == "detail-view" && (
            <div className="">
              <h4>Subscription-Detail-view</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to="/subscription">
                    <FontAwesomeIcon icon={faHome} /> Subscription
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Detail-view</li>
              </ul>
            </div>
          )}
          {props.type == "add-subscription" && (
            <div className="">
              <h4>Add Subscription</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to={"/subscription"}>
                    <FontAwesomeIcon icon={faHome} /> Subscription
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Add Subscription</li>
              </ul>
            </div>
          )}
          {props.type == "edit-subscription" && (
            <div className="">
              <h4>Edit Subscription</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to={"/subscription"}>
                    <FontAwesomeIcon icon={faHome} /> Subscription
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Edit Subscription</li>
              </ul>
            </div>
          )}
          {/* REFER AND EARN */}
          {props.type == "referEarn" && <h4>Refer and Earn</h4>}
          {/* {REVENUE} */}
          {props.type == "revenues" && <h4>REVENUE LIST</h4>}
          {/* SETTINGS */}
          {props.type == "setting" && <h4>Settings</h4>}
          {props.type == "addFare" && (
            <div className="">
              <h4>Add Fare</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to="/setting">
                    <FontAwesomeIcon icon={faHome} /> Setting
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Add Fare</li>
              </ul>
            </div>
          )}
          {props.type == "carType" && <h4>Car Type</h4>}
          {props.type == "editFare" && (
            <div className="">
              <h4>Edit Fare</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to="/setting">
                    <FontAwesomeIcon icon={faHome} /> Setting
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Edit Fare</li>
              </ul>
            </div>
          )}
          {props.type == "add-zone" && (
            <div className="">
              <h4>Add Location</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to={"/setting"}>
                    <FontAwesomeIcon icon={faHome} /> Location
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Add Location</li>
              </ul>
            </div>
          )}
          {props.type == "edit-zone" && (
            <div className="">
              <h4>Edit Location</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to={"/setting"}>
                    <FontAwesomeIcon icon={faHome} /> Location
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Edit Location</li>
              </ul>
            </div>
          )}
          {/* CANCELLED LIST */}

          {props.type == "cancelled" && <h4>Cancelled</h4>}
          {props.type == "view-rides" && (
            <div className="">
              <h4>View Rides</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to="/cancelled">
                    <FontAwesomeIcon icon={faHome} /> Cancelled
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>View-Rides</li>
              </ul>
            </div>
          )}

          {/* ACTIVE LOG */}

          {props.type == "active-log" && <h4>Active Log</h4>}

          {props.type == "activity-view" && (
            <div className="">
              <h4>Activity-Log-View</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to="/activeLog">
                    <FontAwesomeIcon icon={faHome} /> Activity Log
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Detail-view</li>
              </ul>
            </div>
          )}

          {/* {HELP_SUPPORT} */}

          {props.type == "help-support" && <h4>Help And Support</h4>}

          {/* SOS */}
          {props.type == "sos" && <h4>SOS</h4>}

          {/* REPORT */}

          {props.type == "report" && <h4>Report</h4>}

          {/* REVIEW AND RATINGS */}

          {props.type == "review-rating" && <h4>Review and Ratings</h4>}

          {/* PUSH NOTIFICATION */}

          {props.type == "push-notification" && <h4>Notification</h4>}

          {/* OFFLINE BOOKING */}

          {props.type == "offline-booking" && <h4>Offline Booking</h4>}

          {props.type == "viewBooking" && (
            <div className="">
              {" "}
              <h4>View Booking</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to="/offline-booking">
                    <FontAwesomeIcon icon={faHome} /> Offline Booking
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>View Booking</li>
              </ul>
            </div>
          )}

          {props.type == "view-offline" && (
            <div className="">
              {" "}
              <h4>View Offline Booking</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to="/offline-booking">
                    <FontAwesomeIcon icon={faHome} /> Offline Booking
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>View Offline Booking</li>
              </ul>
            </div>
          )}

          {props.type == "addBooking" && (
            <div className="">
              {" "}
              <h4>Add Booking</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to="/offline-booking">
                    <FontAwesomeIcon icon={faHome} /> Offline Booking
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>Add Booking</li>
              </ul>
            </div>
          )}

          {/* SCHEDULE RIDE */}

          {props.type == "schedule-ride" && <h4>Schedule Ride</h4>}
          {props.type == "view-schedule" && (
            <div className="">
              {" "}
              <h4>View Schedule</h4>
              <ul className="d-flex align-items-center bread-crumb mt-1 mb-0 ps-0">
                <li className="me-1">
                  <Link to="/schedule-ride">
                    <FontAwesomeIcon icon={faHome} /> Schedule Ride
                  </Link>
                </li>
                <FontAwesomeIcon className="me-1" icon={faChevronRight} />
                <li>View Schedule</li>
              </ul>
            </div>
          )}

          {/* PAY TAX */}

          {props.type == "payTax" && <h4>Pay Tax</h4>}
        </div>

        {/* OTHERS */}

        {/* <div className="d-flex">
          <div className="header-right d-flex">
            <a>
              <FontAwesomeIcon icon={faUser} />
            </a>
            <a>
              <FontAwesomeIcon icon={faBell} />
              <div className="notif-status"></div>
            </a>
          </div>
        </div> */}
        <div className="d-flex justify-content-center align-items-center">
          <div
            className="header-right d-flex"
            style={{ position: "relative", width: "100%" }}
          >
            <Link
              onClick={notify}
              to={"/notification"}
              className="bg-dark text-light notif-btn"
              style={{ marginTop: "5px" }}
            >
              <FontAwesomeIcon icon={faBell} />
              {status == 1 ? <div className="notif-status"></div> : null}
            </Link>
            <div className="noti_count">
              <p className="n_count">10</p>
            </div>
            <div className="notification">
              <button className="notis">
                <h6 className="f2 ">
                  Wheely This app is password protected by applock
                </h6>
                <p className="m-0 f1">
                  This app is password protected by applock by the user
                </p>
              </button>
              <button className="notis">
                <h6 className="f2 ">Instagram</h6>
                <p className="m-0">This app is password protected by applock</p>
              </button>
              <button className="notis">
                <h6 className="f2 ">Snapchat</h6>
                <p className="m-0">This app is password protected by applock</p>
              </button>
              <button className="notis">
                <h6 className="f2 ">Wheely</h6>
                <p className="m-0">This app is password protected by applock</p>
              </button>
              <button className="notis">
                <h6 className="f2 ">Instagram</h6>
                <p className="m-0">This app is password protected by applock</p>
              </button>
              <button className="notis">
                <h6 className="f2 ">Snapchat</h6>
                <p className="m-0">This app is password protected by applock</p>
              </button>
            </div>
          </div>
          <Dropdown>
            <div className="header-right d-flex d-grid">
              <Dropdown.Toggle
                className="border-0 d-grid"
                style={{ background: "transparent" }}
                id="dropdown-basic"
              >
                <a className="bg-dark text-light">
                  <FontAwesomeIcon icon={faUser} />
                </a>
              </Dropdown.Toggle>
              <p className="user-name text-uppercase">{user?.username}</p>
            </div>
            <Dropdown.Menu>
              <Link onClick={Log} style={{ textDecoration: "none" }} to="#">
                <Dropdown.Item style={{ textDecoration: "none" }}>
                  Logout
                </Dropdown.Item>
              </Link>

              <Dropdown.Item>
                <Link
                  className="text-dark"
                  style={{ textDecoration: "none" }}
                  to="/change-password"
                >
                  Change Password
                </Link>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
      <div className="btm-header-rit-top"></div>
    </div>
  );
};

export default TopHeader;
