import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Form } from "react-bootstrap";
import swal from "sweetalert";
import { useLocation, useNavigate } from "react-router-dom";
import TopHeader from "../../../component/TopHeader";
import { hidePwdImg, showPwdImg } from "../../../assets/img";
import { ToastContainer, toast } from "react-toastify";
import { CiSquareRemove } from "react-icons/ci";
import { IoIosAdd } from "react-icons/io";
import {
  useAddSubscriptionMutation,
  useLazyEditSubscriptionQuery,
  useUpdateSubscriptionMutation,
} from "../../../constants/redux/api/api";

const AddSubscription = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const Id = location?.state?.id;
  const type = location?.state?.type;

  const [input, setInput] = useState([]);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [validity, setValidity] = useState("");
  const [duration, setDuration] = useState("");
  const [no_of_days, setNo_of_days] = useState("");
  const [offer_price, setOffer_price] = useState("");
  const [extra_details, setExtra_details] = useState([{ value: "" }]);
  const [btn, setBtn] = useState(false);

  const [nameErr, setNameErr] = useState(false);
  const [priceErr, setPriceErr] = useState(false);
  const [validityErr, setValidityErr] = useState(false);
  const [durationErr, setDurationErr] = useState(false);
  const [no_of_daysErr, setNo_of_daysErr] = useState(false);
  const [offer_priceErr, setOffer_priceErr] = useState(false);
  const [extra_detailsErr, setExtra_detailsErr] = useState(false);
  //RTK
  const [addsubscriptionApi] = useAddSubscriptionMutation();
  const [updateSubscriptionApi] = useUpdateSubscriptionMutation();
  const [editSubscriptionApi] = useLazyEditSubscriptionQuery();

  const addZone = () => {
    let extra_detailsChek = 1;
    extra_details.map((item, ind) => {
      if (item.value) {
        extra_detailsChek = 1;
      } else {
        extra_detailsChek = extra_detailsChek + 1;
      }
    });
    if (
      name?.length == 0 ||
      price?.length == 0 ||
      duration?.length == 0 ||
      no_of_days?.length == 0 ||
      offer_price?.length == 0 ||
      extra_detailsChek !== 1
    ) {
      setNameErr(true);
      setPriceErr(true);
      setDurationErr(true);
      setNo_of_daysErr(true);
      setOffer_priceErr(true);
      setExtra_detailsErr(true);
    } else {
      let formData = new FormData();
      formData.append("name", name);
      formData.append("actual_price", price);
      formData.append("offer_price", offer_price);
      formData.append("no_of_days", no_of_days);
      formData.append("duration", duration);

      extra_details?.map((item, ind) => {
        if (item.value) {
          formData.append(`extra_details[${ind}]`, item.value);
        }
      });

      if (Id) {
        setBtn(true);
        updateSubscriptionApi({ id: Id, payload: formData })
          .then((res) => {
            setBtn(false);
            if (res?.data.status == "success") {
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
              navigate("/subscription");
            } else {
              toast.error(res?.data?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
            }
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      } else {
        setBtn(true);
        addsubscriptionApi(formData)
          .unwrap()
          .then((res) => {
            setBtn(false);
            if (res?.status == "success") {
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
              navigate("/subscription");
            } else {
              toast.error(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
            }
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      }
    }
  };

  const view = () => {
    editSubscriptionApi(Id)
      .unwrap()
      .then((res) => {
        if (res?.status == "success") {
          setName(res?.subscription?.name);
          setPrice(res?.subscription?.actual_price);
          setOffer_price(res?.subscription?.offer_price);
          setNo_of_days(res?.subscription?.no_of_days);
          setDuration(res?.subscription?.duration);

          if (res?.subscription?.extra_details?.length > 0) {
            let temp = [];
            res?.subscription?.extra_details?.map((item, ind) => {
              temp.push({ value: item });
            });
            setExtra_details(temp);
          }
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (Id) {
      view();
    }
  }, []);

  const addExtraDetails = () => {
    let temp = [...extra_details];
    let count = 1;
    temp.map((item, ind) => {
      if (item.value) {
        count = 1;
      } else {
        count = count + 1;
      }
    });
    if (count == 1) {
      setExtra_detailsErr(false);
      temp.push({ value: "" });
      setExtra_details(temp);
    } else {
      // toast.error("Please Fil Extra Details", {
      //   position: "top-right",
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      //   autoClose: 2000,
      // });
      setExtra_detailsErr(true);
    }
  };

  const removeExtraDetails = (index) => {
    let temp = [...extra_details];
    temp.splice(index, 1);
    setExtra_details(temp);
  };

  const handleExtraDetails = (event, index) => {
    let temp = [...extra_details];
    temp[index]["value"] = event;
    setExtra_details(temp);
  };

  return (
    <aside>
      {type == "edit" ? (
        <TopHeader type="edit-subscription" />
      ) : (
        <TopHeader type="add-subscription" />
      )}
      <article className="p-3">
        <div className="p-3">
          <div className="p-3 bg-white rounded-2">
            <Form className="d-flex flex-wrap">
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Tittle
                    </label>
                    <input
                      type="Name"
                      id="example-if-email"
                      name="name"
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Tittle"
                      value={name}
                    />
                    {name?.length == 0 && nameErr && (
                      <p className="err-p">Enter Tittle</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Price
                    </label>
                    <input
                      type="number"
                      id="example-if-email"
                      name="username"
                      onChange={(e) => setPrice(e.target.value)}
                      placeholder="price"
                      value={price}
                    />
                    {price?.length == 0 && priceErr && (
                      <p className="err-p">Enter Price</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Offer Price
                    </label>
                    <input
                      type="number"
                      id="example-if-email"
                      name="number"
                      onChange={(e) => setOffer_price(e.target.value)}
                      placeholder="Offer Price"
                      value={offer_price}
                    />
                    {offer_price?.length == 0 && offer_priceErr && (
                      <p className="err-p">Enter Offer Price</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      No of Days
                    </label>
                    <input
                      type="name"
                      id="example-if-email"
                      name="name"
                      onChange={(e) => setNo_of_days(e.target.value)}
                      placeholder="No of Days"
                      value={no_of_days}
                    />
                    {no_of_days?.length == 0 && no_of_daysErr && (
                      <p className="err-p">Enter No of Days</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                      Duration
                    </label>
                    <input
                      type="number"
                      id="example-if-email"
                      name="number"
                      onChange={(e) => setDuration(e.target.value)}
                      placeholder="Duration"
                      value={duration}
                    />
                    {duration?.length == 0 && durationErr && (
                      <p className="err-p">Enter Duration</p>
                    )}
                  </div>
                </div>
              </div>

              {extra_details?.map((item, ind) => {
                return (
                  <div
                    className="form-group col-12 col-sm-6 col-lg-6 ps-0"
                    key={ind}
                  >
                    <div className="form-check ps-0">
                      <div className="px-1 col-sm-12 modal-label-input">
                        <div className="d-flex">
                          <label className="text-primary-dark" for="">
                            Extra Details
                          </label>
                          {ind == 0 && (
                            <div
                              role={"button"}
                              onClick={() => addExtraDetails()}
                            >
                              <IoIosAdd fontSize={24} />
                            </div>
                          )}
                        </div>
                        <div className="d-flex">
                          <input
                            type="text"
                            id="example-if-email"
                            name="number"
                            onChange={(e) =>
                              handleExtraDetails(e.target.value, ind)
                            }
                            placeholder="Extra Details"
                            value={item?.value}
                          />
                          {ind !== 0 && (
                            <div
                              role={"button"}
                              onClick={() => removeExtraDetails(ind)}
                            >
                              <CiSquareRemove fontSize={24} />
                            </div>
                          )}
                        </div>
                        {item?.value?.length == 0 && extra_detailsErr && (
                          <p className="err-p">Enter Extra Details </p>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}

              <div className="mt-4 ms-auto">
                <button
                  type="button"
                  className="comn-btn bg-success-gradient px-3 py-2 me-2"
                  onClick={() => addZone()}
                  disabled={btn ? true : false}
                >
                  Submit
                </button>
              </div>
            </Form>
          </div>
        </div>
      </article>
    </aside>
  );
};

export default AddSubscription;
