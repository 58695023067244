import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import TopHeader from "../../../component/TopHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Tab, Tabs } from "react-bootstrap";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
import ShareRevenue from "./ShareRevenue";
import TranscationRevenue from "./Transcation";
import Tax from "./Tax";

const RevenueList = () => {
  const [data, setData] = useState();
  return (
    <aside>
      <TopHeader type="revenues" />
      <article className="p-3">
        <Tabs
          defaultActiveKey="Detail-View"
          id="uncontrolled-tab-example"
          className="mb-3 tab-appoinment"
        >
          <Tab eventKey="Detail-View" title="Revenue">
            <div className="p-3">
              <ShareRevenue />
            </div>
          </Tab>
          <Tab eventKey="Detail" title="Subscription">
            <div className="p-3">
              <TranscationRevenue />
            </div>
          </Tab>
          <Tab eventKey="Details" title="Tax">
            <div className="p-3">
              <Tax />
            </div>
          </Tab>
        </Tabs>
      </article>

    </aside>
  );
};

export default RevenueList;
