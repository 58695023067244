import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Input,
  SkeletonText,
  Text,
} from "@chakra-ui/react";
import { FaLocationArrow, FaTimes } from "react-icons/fa";

import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  Autocomplete,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { useRef, useState } from "react";
import { MAPS_API_KEY } from "../../../constants/redux/api/constants";

const center = { lat: 13.067439, lng: 80.237617 };

const DistanceMap = ({ setLocationData }) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: MAPS_API_KEY,
    libraries: ["places"],
  });

  const [map, setMap] = useState(/** @type google.maps.Map */ (null));
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [distance, setDistance] = useState("");
  const [duration, setDuration] = useState("");
  const [fareEstimation, setFareEstimation] = useState("");

  /** @type React.MutableRefObject<HTMLInputElement> */
  const originRef = useRef();
  /** @type React.MutableRefObject<HTMLInputElement> */
  const destiantionRef = useRef();

  if (!isLoaded) {
    return <SkeletonText />;
  }

  const getEstimation = (distance) => {
    let data = {
      distance: distance,
    };
  };

  async function calculateRoute() {
    if (originRef.current.value === "" || destiantionRef.current.value === "") {
      return;
    } else {
      // eslint-disable-next-line no-undef
      const directionsService = new google.maps.DirectionsService();
      const results = await directionsService.route({
        origin: originRef.current.value,
        destination: destiantionRef.current.value,
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
      });

      // console.log("results", results);
      setLocationData(results);
      // console.log("lon", results.routes[0].legs[0].start_location.lng());
      // console.log("lat", results.routes[0].legs[0].start_location.lat());

      setDirectionsResponse(results);
      let split_string = results.routes[0].legs[0].distance.text.split("km");
      getEstimation(split_string[0]);
      setDistance(results.routes[0].legs[0].distance.text);
      setDuration(results.routes[0].legs[0].duration.text);
    }
  }

  function clearRoute() {
    setDirectionsResponse(null);
    setDistance("");
    setDuration("");
    originRef.current.value = "";
    destiantionRef.current.value = "";
  }

  return (
    <>
      {isLoaded ? (
        <>
          <div>
            <Flex
              position="relative"
              flexDirection="column"
              alignItems="center"
              h="80vh"
              w="100%"
            >
              <Box position="absolute" left={0} top={0} h="100%" w="100%">
                {/* Google Map Box */}
                <GoogleMap
                  center={center}
                  zoom={15}
                  mapContainerStyle={{ width: "100%", height: "100%" }}
                  options={{
                    zoomControl: true,
                    streetViewControl: false,
                    mapTypeControl: true,
                    fullscreenControl: true,
                  }}
                  onLoad={(map) => setMap(map)}
                >
                  <Marker position={center} />
                  {directionsResponse && (
                    <DirectionsRenderer directions={directionsResponse} />
                  )}
                </GoogleMap>
              </Box>
              <Box
                p={4}
                borderRadius="lg"
                m={4}
                bgColor="white"
                shadow="base"
                minW="container.md"
                zIndex="1"
              >
                <HStack spacing={2} justifyContent="space-between">
                  <Box flexGrow={1}>
                    <Autocomplete>
                      <Input type="text" placeholder="From" ref={originRef} />
                    </Autocomplete>
                  </Box>
                  <Box flexGrow={1}>
                    <Autocomplete>
                      <Input
                        type="text"
                        placeholder="To"
                        ref={destiantionRef}
                      />
                    </Autocomplete>
                  </Box>

                  <ButtonGroup>
                    <Button
                      colorScheme="purple"
                      type="submit"
                      onClick={calculateRoute}
                    >
                      Click Route
                    </Button>
                    <IconButton
                      aria-label="center back"
                      icon={<FaTimes />}
                      onClick={clearRoute}
                    />
                  </ButtonGroup>
                </HStack>
                <HStack spacing={4} mt={4} justifyContent="space-between">
                  <Text>Distance: {distance} </Text>
                  <Text>Duration: {duration} </Text>
                  <Text>Fare Estimation: {"Rs." + fareEstimation} </Text>
                  <IconButton
                    aria-label="center back"
                    icon={<FaLocationArrow />}
                    isRound
                    // onClick={() => {
                    //   map.panTo(center);
                    //   map.setZoom(15);
                    // }}
                  />
                </HStack>
              </Box>
            </Flex>
          </div>
        </>
      ) : null}
    </>
  );
};

export default DistanceMap;
