import React from "react";
import { Form } from "react-bootstrap";
import { useState } from "react";
import TopHeader from "../../../component/TopHeader";
import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import moment from "moment/moment";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faPenAlt, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Row, Col, Pagination, Button } from "react-bootstrap";
import { CiSquareRemove } from "react-icons/ci";
import { IoIosAdd } from "react-icons/io";
import {
  useFaqAddMutation,
  useFaqEditMutation,
  useLazyFaqviewQuery,
} from "../../../constants/redux/api/api";

const Faq = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const Id = location?.state?.id;
  const type = location?.state?.type;
  const [btn, setBtn] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [titleErr, setTitleErr] = useState(false);
  const [descriptionErr, setDescriptionErr] = useState(false);
  const [typesErr, setTypesErr] = useState(false);

  //RTK
  const [faqViewApi] = useLazyFaqviewQuery();
  const [faqAddApi] = useFaqAddMutation();
  const [faqEditApi] = useFaqEditMutation();

  const submitHandler = () => {
    if (
      title?.length == 0 ||
      description?.length == 0 
    ) {
      setTitleErr(true);
      setDescriptionErr(true);
     
    } else {
      let formdata = new FormData();
      formdata.append("title", title);
      formdata.append("description", description);
     

      if (Id) {
        faqEditApi({ id: Id, payload: formdata })
          .unwrap()
          .then((res) => {
            setBtn(false);
            if (res?.status == "success") {
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
              navigate("/faqs");
            } else {
              toast.error(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
            }
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      } else {
        faqAddApi(formdata)
          .unwrap()
          .then((res) => {
            setBtn(false);
            if (res?.status == "success") {
              toast.success(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
              navigate("/faqs");
            } else {
              toast.error(res?.message, {
                position: "top-right",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                autoClose: 2000,
              });
            }
          })
          .catch((err) => {
            setBtn(false);
            console.log("err", err);
          });
      }
    }
  };

  const viewReward = () => {
    faqViewApi(Id)
      .unwrap()
      .then((res) => {
        console.log(res,"res");
        let data = res?.faq;
        setTitle(data?.title);
        setDescription(data?.description);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    if (Id) {
      viewReward();
    }
  }, []);

  return (
    <aside>
      {type == "edit" ? (
        <TopHeader type="editFaq" />
      ) : (
        <TopHeader type="addFaq" />
      )}
      <article className="p-3">
        <div className="p-3">
          <div className="p-3 bg-white rounded-2">
            <Form className="d-flex flex-wrap">
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                    title 
                    </label>
                    <input
                      type="Name"
                      id="example-if-email"
                      name="name"
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder="Title"
                      value={title}
                    />
                    {title?.length == 0 && titleErr && (
                      <p className="err-p">Enter Title</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-group col-12 col-sm-6 col-lg-6 ps-0">
                <div className="form-check ps-0">
                  <div className="px-1 col-sm-12 modal-label-input">
                    <label className="text-primary-dark" for="">
                    description 
                    </label>
                    <input
                      type="Name"
                      id="example-if-email"
                      name="name"
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder="Description"
                      value={description}
                    />
                    {description?.length == 0 && descriptionErr && (
                      <p className="err-p">Enter Description</p>
                    )}
                  </div>
                </div>
              </div>
              
              <div className="mt-4 ms-auto">
                <button
                  type="button"
                  className={`comn-btn bg-success-gradient px-3 py-2 me-2 ${
                    btn ? "opacity-50" : ""
                  }`}
                  onClick={submitHandler}
                  disabled={btn ? true : false}
                >
                  Submit
                </button>
              </div>
            </Form>
          </div>
        </div>
      </article>
    </aside>
  );
};

export default Faq;
